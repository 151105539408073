import { DropdownItem } from "../../components/dropdown.component";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { clearCurrency, doubleToString, maskVal, StringToDouble } from "../../utils";
import { HealthStateModel, setContextHealth } from "./reducer";
import config from '../../../configurations.json';
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { setRedirect } from "../../reducer";

export const Logic = () => {
    const [initialized, setInitialized] = useState(false);
    const currentState = (state: RootState) => state.health;
    const state = useAppSelector(currentState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const configuration = config;

    configuration.health.items = [
        {
            "name": "Clínico Geral",
            "discountFix": StringToDouble(localStorage.getItem('clinico_geral'))
        },
        {
            "name": "Demais Áreas",
            "discountFix": StringToDouble(localStorage.getItem('demais'))
        }
    ];

    const [value, setValue] = useState(doubleToString(state.value) || '');
    const [discount, setDiscount] = useState(state.discount || 0);
    const [total, setTotal] = useState(0);
    function verifyDiscount(index?: number) {
        index = (index ?? dropdownValue!) - 1;
        let val = clearCurrency(value);
        if(index >= 0) {
            let desc = Number((configuration.health.items[index!].discountFix).toFixed(2));
            let total = Number((val >= desc ? val - desc : 0).toFixed(2));
            setDiscount(val => val = total);
            setTotal(total);
        }
        else {
            setDiscount(0);
            setTotal(0);
        }
    }

    const [dropdownValue, setDropdownValue] = useState(state.medicalSpecialty || 0);

    const items = configuration.health.items.map(x => new DropdownItem(configuration.health.items.indexOf(x) + 1, x.name));

    const handleBack = () => {
        dispatch(setRedirect({ redirect: true }));
        navigate("/course" + window.location.search)
    }

    const handleSubmit = () => {
        dispatch(setContextHealth({ page: 'health', value: clearCurrency(value), medicalSpecialty: dropdownValue, discount, cashback: 0 } as HealthStateModel));
        dispatch(setRedirect({ redirect: true }));
        navigate("/total" + window.location.search)
    }

    if (!initialized && state.value) {
        setInitialized(value => value = true);
        verifyDiscount();
    }

    const energyState = (state: RootState) => state.energy;
    const energy = useAppSelector(energyState);
    const farmacyState = (state: RootState) => state.farmacy;
    const farmacy = useAppSelector(farmacyState);
    const gasState = (state: RootState) => state.gas;
    const gas = useAppSelector(gasState);
    const courseState = (state: RootState) => state.course;
    const course = useAppSelector(courseState);

    const getDiscountCard = () => {
        let value = (energy.discount ?? 0) + (farmacy.discount ?? 0) + (gas.discount ?? 0) + (course.discount ?? 0) + discount;
        return !isNaN(value) ? value : 0;
    }

    const getCashbackCard = () => {
        let value = (energy.cashback ?? 0) + (farmacy.cashback ?? 0);
        return !isNaN(value) ? value : 0;
    }

    const enterAction = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const setDropDown = (value: number) => {
        setDropdownValue(val => val = value);
        verifyDiscount(value);
    }

    useEffect(() => {
        if(value) {
            setValue(maskVal(value));
            verifyDiscount();
        }
    }, [value, setValue]);

    return { items, dropdownValue, value, setValue, verifyDiscount, handleBack, handleSubmit, discount, getDiscountCard, getCashbackCard, enterAction, total, setDropDown, model: configuration.health };
}