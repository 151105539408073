import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FormClientStateModel {
    name: string;
    registration: string;
    franchiseId: string;
    franchise: string;
    nearestFranchise: string;
    regional: string;
    attendantName: string;
    email: string;    
    cep: string;

    phone: string;
    cpf: string;
    terms: boolean;
    termsWhatsapp: boolean;
    termsSMS: boolean;
    termsEmail: boolean;
    dropdownValue?: number;
    linkColaborator?: string;
    idpv?: string;
    
    uf?: string;
    bairro?: string;
    cidade?: string;
    atendeEnergiaTodos?: boolean;
    atendeFarmacia?: boolean;
    atendeGas?: boolean;

    ssoUserName?: string;
    ssoUserEmail?: string;
    ssoUserSub?: string;
    ssoIdColaborator?: number;

    isColaboratorLead?: boolean;
}

export const IndexReducer = () => {
    const setContextRule = (model: FormClientStateModel, payload: PayloadAction<FormClientStateModel>) => model = payload.payload;

    const indexSlice = createSlice({
        name: 'formClient',
        initialState: {} as FormClientStateModel,
        reducers: {
            setContext: (model: FormClientStateModel, payload: PayloadAction<FormClientStateModel>) => setContextRule(model, payload),
        }
    });

    return { indexSlice };
}

export const { indexSlice } = IndexReducer();
export const { setContext } = indexSlice.actions;

export default indexSlice.reducer;