import { createSlice } from "@reduxjs/toolkit";
export interface ReceiptStateModel {
    value: number;
}

export const IndexReducer = () => {
    const indexSlice = createSlice({
        name: 'receipt',
        initialState: {} as ReceiptStateModel,
        reducers: {}
    });

    return { indexSlice };
}

export const { indexSlice } = IndexReducer();

export default indexSlice.reducer;