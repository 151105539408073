import React, { KeyboardEventHandler, ReactElement, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { FocusEventHandler } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
declare type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

const InputComponent = (
    props: { 
        title: string, 
        placeholder: string, 
        onChange?: React.ChangeEventHandler<FormControlElement>, 
        isValid: boolean, isInvalid: boolean, 
        enterKeyHint?: 'enter' | 'next', 
        onKeyUp?: KeyboardEventHandler<Element>, 
        onKeyDown?: KeyboardEventHandler<Element>, 
        onBlur?: FocusEventHandler, 
        type: string, 
        disabled?: boolean, 
        autoFocus?: boolean, 
        required?: boolean, 
        value?: string, 
        errorMessage?: string, 
        customLabel?: ReactElement, 
        as?: React.ElementType, 
        mask?: string, 
        loading?: boolean, 
        upperCase?: boolean,
        notice?: string,
        textToCopy?: string
     }) => {
            const { 
                title, 
                placeholder, 
                onChange, 
                isValid, 
                isInvalid, 
                disabled, 
                enterKeyHint, 
                onKeyUp, 
                onKeyDown, 
                onBlur, 
                value, 
                required, 
                type, 
                autoFocus, 
                customLabel, 
                errorMessage, 
                as, 
                mask, 
                loading, 
                upperCase,
                notice,
                textToCopy
            } = 
    props;

    const [copyButtonText, setCopyButtonText] = useState("Copiar link");
    const [copied, setCopied] = useState(false);
    const copyToClipboard = (e: any) => {
        if(textToCopy)
            navigator.clipboard.writeText(textToCopy).then(() => {
                setCopyButtonText("Copiado!");
                setCopied(true);
            }, () => {
                setCopyButtonText("Erro ao copiar!");
                setCopied(false);
            });
    };

    return (
        <Form.Group className="form-group mb-4">
            <Form.Label>{customLabel ? customLabel : (title + (required ? "*" : ""))}</Form.Label>
            <Form.Control
                id={title}
                as={as ?? ReactInputMask}
                maskChar=""
                mask={mask}
                autoFocus={autoFocus ?? false}
                title={title}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isValid={isValid}
                isInvalid={isInvalid}
                disabled={disabled ?? false}
                required={required ?? false}
                enterKeyHint={enterKeyHint}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                className={upperCase? 'text-uppercase': ''}
            />
            {loading ? 
            <>
                <Spinner animation="border" role="status" variant="primary" className='position-absolute spinner-class'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <div className="form-text">Pesquisando {customLabel ? customLabel : title}...</div>
                </>
                : 
                null
            }
            {textToCopy && !loading ? 
            <>
                <Button variant="link" className={`position-absolute button-copytoclipboard ${copied ? "copy-successfully" : ""}`} onClick={copyToClipboard}>
                    <span className="text">{copyButtonText}</span>
                    <svg className="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 5V14H11V5H2ZM1.5 4H11.5C11.6326 4 11.7598 4.05268 11.8536 4.14645C11.9473 4.24021 12 4.36739 12 4.5V14.5C12 14.6326 11.9473 14.7598 11.8536 14.8536C11.7598 14.9473 11.6326 15 11.5 15H1.5C1.36739 15 1.24021 14.9473 1.14645 14.8536C1.05268 14.7598 1 14.6326 1 14.5V4.5C1 4.36739 1.05268 4.24021 1.14645 4.14645C1.24021 4.05268 1.36739 4 1.5 4ZM15 1.5V12.5C15 12.6326 14.9473 12.7598 14.8536 12.8536C14.7598 12.9473 14.6326 13 14.5 13H13V12H14V2H6V3H5V1.5C5 1.36739 5.05268 1.24021 5.14645 1.14645C5.24021 1.05268 5.36739 1 5.5 1H14.5C14.6326 1 14.7598 1.05268 14.8536 1.14645C14.9473 1.24021 15 1.36739 15 1.5ZM4 10.5H9V11.5H4V10.5ZM4 7.5H9V8.5H4V7.5Z" fill="#1D1D1D"/>
                    </svg>
                </Button>
                </>
                : 
                null
            }
            {notice ?
                <>
                <div className="form-text">{notice}</div>
                </>
                :
                null
            }
            <Form.Control.Feedback type="invalid">{errorMessage ?? "Campo obrigatório"}</Form.Control.Feedback>
        </Form.Group>
    );
};

export default InputComponent;