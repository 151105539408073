import { Alert, Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { doubleToCurrency } from "../../utils";
import { Logic } from "./logic";
import { useEffect, useState } from "react";

import { ReactComponent as WhatsAppIcon } from "../../assets/img/icons/ic-whatsapp.svg";
import { ReactComponent as EmailIcon } from "../../assets/img/icons/ic-email.svg";
import { ReactComponent as ReloadIcon } from "../../assets/img/icons/ic-reload.svg";
import { ReactComponent as LikeIcon } from "../../assets/img/icons/ic-like.svg";

import StrippedCardComponent, { ModelObject } from "../../components/stripped-card.component";
import CashbackPartnersComponent, { ModelCashbackPartners } from "../../components/cashback-partners.component";
import PartnersList from '../../../partners.json';
import DropdownComponent from "../../components/dropdown.component";

export default function Total() {
    const { 
        redoCalculations, formClient, energy, farmacy, gas, course, health, 
        configuration, getIndex, getMonthlyWhithoutCard, getAnualWhithoutCard, getDiscounts, getCasbacks, getMonthlyWithCard, getAnualWithCard, 
        print, sendWhatsApp, sendEmail, isColaborator, 
        dropdownMotivoValue, setDropdownMotivoValue, motivos, setDropDownMotivo,
        showDropdownRequiredMotivo, setShowDropdownRequiredMotivo,
        dropdownClienteRetidoValue, setDropdownClienteRetidoValue, clienteRetido, setDropDownClienteRetido,
        showDropdownRequiredRetido, setShowDropdownRequiredRetido,
        verifyForm, verify, formSended, setFormSended, formValid, filledValues,
        valorCartao
    } = Logic();
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container className="mt-0">
            <Row>
                <Col sm={12} lg={5} className="no-print">
                    <div className="sticky-top pt-4">
                        <Row className='align-items-center'>
                            <Col className="col-auto">
                                <img src="/assets/img/icons/ic-calculadora.svg" alt="Ícone Calculadora" />
                            </Col>
                        </Row>
                        
                        <Row className='mt-4 mb-2 page-title'>
                            {filledValues() ?
                            <Col>
                                <h4 className="fw-normal">
                                    Boas-vindas, {formClient.name}!
                                </h4>
                                <h1 className="fw-normal mt-4">
                                    Com o Cartão de TODOS, por apenas 
                                    <br className="d-none d-xl-block"/>
                                    <b> R$ {valorCartao}/mês</b> você pode <b>economizar até 
                                    <br className="d-none d-xxl-block"/>
                                    <span className="d-inline d-xxl-none"> </span>{doubleToCurrency(getDiscounts())}</b> e ainda ganhar <b>{doubleToCurrency(getCasbacks())} de 
                                    <br className="d-none d-xxl-block"/>
                                    <span className="d-inline d-xxl-none"> </span>cashback</b> todo mês!
                                </h1>
                            </Col>
                            :
                            <Col>
                                <h4 className="fw-normal">
                                    Ops, {formClient.name}!
                                </h4>
                                <h1 className="fw-normal mt-4">
                                    Parece que você não calculou os 
                                    <br className="d-none d-xl-block"/>
                                    descontos que estão disponíveis pra 
                                    <br className="d-none d-xl-block"/>
                                    você com o <b>Cartão de TODOS</b>. 
                                    <br className="d-none d-xl-block"/>
                                    Que tal tentar novamente?
                                    
                                </h1>
                            </Col>
                            }
                        </Row>


                        {filledValues() ?
                        <>
                        <Row className="mt-4">
                            <Col>
                                <Button variant="outline-text-info" size="sm" type="button" onClick={window.print}>Imprimir relatório</Button>
                            </Col>
                        </Row>
                        <div>
                            <a className="mt-5 text-dark d-flex align-items-center" onClick={sendWhatsApp}>
                                <WhatsAppIcon></WhatsAppIcon>
                                <span className="ps-1 ms-2">Enviar via <b>WhatsApp</b></span>
                            </a>
                        </div>

                        <div>
                            <a className="mt-3 text-dark d-flex align-items-center" onClick={sendEmail}>
                                <EmailIcon></EmailIcon>
                                <span className="ps-1 ms-2">Enviar via <b>e-mail</b></span>
                            </a>
                        </div>
                        </>
                        :
                        <></>
                        }

                        <a onClick={redoCalculations} className="mt-3 text-dark d-flex align-items-center">
                            <ReloadIcon></ReloadIcon>
                            <span className="ps-1 ms-2">Refazer os <b>cálculos</b></span>
                        </a>

                    </div>
                </Col>
                <Col sm={12} lg={7}>
                    {filledValues() ?
                    <>
                    <Row className="card-resume mt-5 print-space-reset">
                        <Col sm={6} className="bg-light py-3 px-4 card-border-start-radius print-space-reset">
                            <Row className="pt-5 pb-2 print-space-reset">
                                <Col sm={12}>
                                    <h2 className="title">
                                        Estimativa de gastos 
                                        <br />
                                        sem o Cartão de TODOS
                                    </h2>
                                </Col>
                            </Row>
                            <Row className="mt-3 print-space-reset">
                                <Col>
                                    <table className='table-resume w-100'>
                                        <tbody>
                                            <tr className='line-details'>
                                                <td className='info'>Gasto mensal</td>
                                                <td className='value text-end'>{doubleToCurrency(getMonthlyWhithoutCard())}</td>
                                            </tr>
                                            <tr className='line-details'>
                                                <td className='info'>Gasto anual</td>
                                                <td className='value text-end'>{doubleToCurrency(getAnualWhithoutCard())}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6} className="bg-secondary text-white py-3 px-4 card-border-end-radius print-space-reset">
                            <Row className="pb-3 print-space-reset">
                                <Col className="col-auto">
                                    <LikeIcon></LikeIcon>
                                </Col>
                            </Row>
                            <Row className="pb-3 print-space-reset">
                                <Col sm={12} className="fw-bold"><h2>Estimativa de benefícios com o Cartão de TODOS</h2></Col>
                            </Row>
                            
                            <Row className="mt-3 print-space-reset">
                                <Col>
                                    <table className='table-resume w-100'>
                                        <tbody>
                                            <tr className='line-details'>
                                                <td className='info text-white'>Desconto acumulado</td>
                                                <td className='value text-end text-white'>{doubleToCurrency(getDiscounts())}</td>
                                            </tr>
                                            <tr className='line-details'>
                                                <td className='info text-white'>Dinheiro de volta acumulado</td>
                                                <td className='value text-end text-white'>{doubleToCurrency(getCasbacks())}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='table-resume w-100'>
                                        <tbody className="border-0">
                                            <tr className='line-details'>
                                                <td className='info text-white'>Gasto mensal</td>
                                                <td className='value text-end text-white'>{doubleToCurrency(getMonthlyWithCard())}</td>
                                            </tr>
                                            <tr className='line-details'>
                                                <td className='info text-white'>Gasto anual</td>
                                                <td className='value text-end text-white'>{doubleToCurrency(getAnualWithCard())}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col >
                    </Row>
                    <Row className="mt-5 page-title">
                        <Col><h1>Benefícios <span className="fw-normal">com o</span> Cartão de TODOS</h1></Col>
                    </Row>
                    <div className="cards-benefits">
                        <StrippedCardComponent index={getIndex(energy.value)} page={configuration.energy.page} logo={configuration.energy.logo} height={configuration.energy.height} description={configuration.energy.description} readMore={configuration.energy.readMore} model={energy as ModelObject} discount={configuration.energy.discount} cashback={configuration.energy.cashback} />
                        <StrippedCardComponent index={getIndex(farmacy.value)} page={configuration.farmacy.page} logo={configuration.farmacy.logo} height={configuration.farmacy.height} description={configuration.farmacy.description} readMore={configuration.farmacy.readMore} model={farmacy as ModelObject} discount={configuration.farmacy.items[farmacy.product - 1]?.discount} cashback={configuration.farmacy.items[farmacy.product - 1]?.cashback} />
                        <StrippedCardComponent index={getIndex(gas.value)} page={configuration.gas.page} logo={configuration.gas.logo} height={configuration.gas.height} description={configuration.gas.description} readMore={configuration.gas.readMore} model={gas as ModelObject} discount={configuration.gas.items[gas.frequency - 1]?.discount} />
                        <StrippedCardComponent index={getIndex(course.value)} page={configuration.course.page} logo={configuration.course.logo} height={configuration.course.height} description={configuration.course.description} readMore={configuration.course.readMore} model={course as ModelObject} text="Plano" discountFix={configuration.course.items[course.course - 1]?.discountFix} />
                        <StrippedCardComponent index={getIndex(health.value)} page={configuration.health.page} logo={configuration.health.logo} height={configuration.health.height} description={configuration.health.description} readMore={configuration.health.readMore} model={health as ModelObject} text={configuration.health.items[health.medicalSpecialty - 1]?.name} discountFix={configuration.health.items[health.medicalSpecialty - 1]?.discountFix} />
                    </div>

                    <Row className="mt-5 pt-3 page-title no-print">
                        <Col>
                            <Button href={`https://solicite.cartaodetodos.com.br/${formClient.idpv ? '?idpv='+formClient.idpv : ""}`} target="_blank" variant="secondary" size="sm">Peça já o seu</Button>
                        </Col> 
                    </Row>
                    </>
                    :
                    <></>
                    }

                    <Row className={filledValues() ? "mt-5 pt-3 page-title" : "mt-5 pt-5 page-title"}>
                        <Col>
                            <h1 className="fw-normal">Veja também nossos <b>parceiros</b> com <b>cashback</b></h1>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <CashbackPartnersComponent partners={PartnersList as unknown as ModelCashbackPartners[]} />
                        </Col>
                    </Row>

                    <Row className="mt-4 pt-3 pb-3 page-title no-print">
                        <Col>
                            <Button href="https://linktr.ee/cartao_de_todos" target="_blank" variant="secondary" size="sm">Baixar o app</Button>
                        </Col> 
                    </Row>

                    {isColaborator && filledValues() ?
                        <>
                        <hr className="mt-5 bg-neon"/>
                        <Row className="mt-5 pt-3 page-title">
                            <Col>
                                <h1 className="fw-normal">Área do colaborador</h1>
                            </Col>
                        </Row>
                        <Form>
                            <Row>
                                <Col sm={12} lg={6}>
                                    <DropdownComponent
                                        title="O cliente foi retido?"
                                        value={dropdownClienteRetidoValue ? clienteRetido.find(x => x.key === dropdownClienteRetidoValue)!.value : undefined}
                                        setDropdownValue={(value: number) => setDropDownClienteRetido(value)}
                                        items={clienteRetido}
                                        showDropdownRequired={showDropdownRequiredRetido}
                                        required
                                    />
                                </Col>
                                <Col sm={12} lg={6}>
                                    <DropdownComponent
                                        title="Motivo?"
                                        value={dropdownMotivoValue ? motivos.find(x => x.key === dropdownMotivoValue)!.value : undefined}
                                        setDropdownValue={(value: number) => setDropDownMotivo(value)}
                                        items={motivos}
                                        showDropdownRequired={showDropdownRequiredMotivo}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2 pb-3 page-title no-print">
                                <Col>
                                    <Button variant="secondary" size="sm" type="button" disabled={formValid} onClick={verifyForm}>Enviar</Button>

                                    {formSended?
                                    <Alert className="mt-3 mb-3" variant="success" onClose={() => setFormSended(false)} dismissible>
                                        <span>Enviado com sucesso!</span>
                                    </Alert>
                                    :
                                    <></>
                                    }
                                </Col> 
                            </Row>
                        </Form>
                        </>
                        :
                        <></>
                    }
                </Col>
            </Row>
        </Container>
    );
}