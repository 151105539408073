import axios from "axios";
import { HubspotModel } from "../models";

export const HubspotService = () => {
    const sendLead = async (data: any) => {
        try {
            var response = await axios.post(`${process.env.REACT_APP_API}/pessoa`, JSON.stringify(data))
            return response.data;
        } catch (error) {
            return error;
        }
    };

    return { sendLead };
}