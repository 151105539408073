import CurrencyInputComponent from "../../components/currency-input.component";
import CardDiscountComponent from "../../components/card-discount.component";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DropdownComponent from "../../components/dropdown.component";
import { doubleToCurrency } from "../../utils";
import { Logic } from "./logic";
import { useEffect } from "react";
import InputComponent from "../../components/input.component";

export default function Health() {
    const { items, dropdownValue, value, setValue, verifyDiscount, handleBack, handleSubmit, discount, getDiscountCard, getCashbackCard, enterAction, total, setDropDown, model } = Logic();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <Row className="align-items-center">
                <Col className='col-lg-4 mt-4 mt-lg-0 order-1 order-lg-0'>
                    <Row className='mb-3 align-items-center'>
                        <Col className="col-auto">
                            <img src="/assets/img/icons/ic-calculadora.svg" alt="Ícone Calculadora" />
                        </Col >
                        <Col className="col-auto ms-2">
                            <img src="/assets/img/benefits/logo-amor-saude.svg" alt="Amor Saúde" />
                        </Col>
                    </Row>
                    
                    <Row className='mb-4 page-title'>
                        <Col>
                            <h1 className="text-primary">
                                Quanto você gasta com 
                                <br /> 
                                consultas médicas?
                            </h1>
                        </Col>
                    </Row>

                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <DropdownComponent
                                    title="Selecione a área médica"
                                    value={dropdownValue ? items.find(x => x.key === dropdownValue)!.value : undefined}
                                    setDropdownValue={(value: number) => setDropDown(value)}
                                    items={items}
                                />
                                <InputComponent
                                    title="Valor mensal (R$)"
                                    type="text"
                                    placeholder="R$ 00,00"
                                    value={value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
                                    onKeyUp={() => verifyDiscount()}
                                    disabled={dropdownValue === undefined || dropdownValue === 0}
                                    isValid={value !== undefined}
                                    isInvalid={false}
                                    enterKeyHint="enter"
                                    onKeyDown={enterAction}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <h4 className='title-detail'>
                                    Estimativa de economia 
                                </h4>
                            </Col>
                        </Row>
                        <Row className="pb-5">
                            <Col>
                                <table className='table-discounts w-100'>
                                    <tbody>
                                        <tr className='line-details'>
                                            <td className='info'>
                                                Valor da consulta com o 
                                                <br />
                                                <b>Cartão de TODOS</b>
                                            </td>
                                            <td className='value text-end'>{doubleToCurrency(model.items[dropdownValue! - 1]?.discountFix ?? 0)}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr className='line-total'>
                                            <td className='info'>Benefício mensal</td>
                                            <td className='value text-end'>{doubleToCurrency(total)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>

                        <Row className="mt-3 align-items-center">
                            <Col>
                                <a className="text-dark" onClick={handleBack}><u>Voltar</u></a>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button className="text-nowrap" variant="secondary" size="sm" type="button" onClick={handleSubmit}>Todos os descontos</Button>
                            </Col>  
                        </Row>
                    </Form>
                </Col>
                <Col lg={4} className="mb-sm-0 d-flex order-0 order-lg-1 px-0">
                    <CardDiscountComponent discount={getDiscountCard()} cashback={getCashbackCard()} />
                </Col>
                <Col lg={4} className="d-none d-lg-block text-center order-2">
                    <img src="./assets/img/banners/banner-medico.png" alt="Banner Amor Saúde" className='img-fluid'/>
                </Col>
            </Row>
        </Container>
    );
}
