import { DropdownItem } from "../../components/dropdown.component";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { clearCurrency, doubleToString, GetAbrangencia, maskVal } from "../../utils";
import { FarmacyStateModel, setContextFarmacy } from "./reducer";
import configuration from '../../../configurations.json';
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { setRedirect } from "../../reducer";

export const Logic = () => {
    const [initialized, setInitialized] = useState(false);
    const currentState = (state: RootState) => state.farmacy;
    const state = useAppSelector(currentState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const abrangencia = GetAbrangencia();

    const formClientState = (state: RootState) => state.formClient;
    const formClient = useAppSelector(formClientState);
    
    const isColaborator = formClient.ssoUserSub ? true : false;

    const [value, setValue] = useState(doubleToString(state.value) || '');
    const [discount, setDiscount] = useState(state.discount || 0);
    const [cashback, setCashback] = useState(state.cashback || 0);
    const [total, setTotal] = useState(0);
    function verifyDiscount(index?: number) {
        index = (index! ?? dropdownValue!) - 1;

        if(index >= 0) {
            let desc = Number((clearCurrency(value) * configuration.farmacy.items[index!].discount).toFixed(2));
            let cash = Number((clearCurrency(value) * configuration.farmacy.items[index!].cashback).toFixed(2));
            setDiscount(val => val = desc);
            setCashback(val => val = cash);
            setTotal(desc + cash);
        }
        else {
            setDiscount(0);
            setCashback(0);
            setTotal(0);
        }
    }

    const [dropdownValue, setDropdownValue] = useState(state.product || 0);

    const items = configuration.farmacy.items.map(x => new DropdownItem(configuration.farmacy.items.indexOf(x) + 1, x.name));

    const handleBack = () => {
        var prevRoute = isColaborator ? "/colaborador" : "/";
        if (abrangencia.atendeEnergiaTodos)
            prevRoute = "/energy";
        dispatch(setRedirect({ redirect: true }));
        navigate(prevRoute + window.location.search);
    }

    const handleSubmit = () => {
        dispatch(setContextFarmacy({ page: 'farmacy', value: clearCurrency(value), product: dropdownValue, discount, cashback } as FarmacyStateModel));
        dispatch(setRedirect({ redirect: true }));
        
        let nextRoute = "/farmacy";
        if (abrangencia.atendeGas)
            nextRoute = "/gas";
        else
            nextRoute = "/phone";
        navigate(nextRoute + window.location.search);
    }

    if (!initialized && state.value) {
        setInitialized(value => value = true);
        verifyDiscount();
        setTotal(discount + cashback);
    }

    const energyState = (state: RootState) => state.energy;
    const energy = useAppSelector(energyState);

    const getDiscountCard = () => {
        let value = (energy.discount ?? 0) + discount;
        return !isNaN(value) ? value : 0;
    }

    const getCashbackCard = () => {
        let value = (energy.cashback ?? 0) + cashback;
        return !isNaN(value) ? value : 0;
    }

    const enterAction = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const setDropDown = (value: number) => {
        setDropdownValue(val => val = value);
        verifyDiscount(value);
    }

    useEffect(() => {
        if(value) {
            setValue(maskVal(value));
            verifyDiscount();
        }
    }, [value, setValue]);

    return { value, setValue, items, dropdownValue, handleBack, handleSubmit, verifyDiscount, discount, cashback, getDiscountCard, getCashbackCard, enterAction, setDropDown, total, model: configuration.farmacy };
}