import { Dropdown, Form } from 'react-bootstrap';
import { ReactElement } from 'react';

export class DropdownItem {
    key: number;
    value: string;

    constructor(key: number, value: string) {
        this.key = key;
        this.value = value;
    }
}

const DropdownComponent = (props: { title: string, items: DropdownItem[], setDropdownValue: Function, showDropdownRequired?: boolean, value?: string, placeholder?: string, required?: boolean, customLabel?: ReactElement }) => {
    const { title, items, value, placeholder, required, customLabel, setDropdownValue, showDropdownRequired } = props;

    return (
        <Form.Group className="mb-3">
            <Form.Label>{customLabel ? customLabel : title}{required ? "*" : ""}</Form.Label>
            <Dropdown
                onSelect={(eventKey: string | null, e: React.SyntheticEvent<unknown>) => setDropdownValue(JSON.parse((e.target as HTMLElement).getAttribute("value")!))}>
                <Dropdown.Toggle id={title} className={value ? "is-valid text-dark" : (showDropdownRequired ? "is-invalid" : "")}>
                    {value ?? (placeholder ?? "Selecione")}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {items.map(x => <Dropdown.Item value={x.key} key={x.key}>{x.value}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
            {showDropdownRequired && required && value === undefined ? <div className="invalid">Seleção obrigatória</div> : <></>}
        </Form.Group>
    );
};

export default DropdownComponent;