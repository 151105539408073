import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface EnergyStateModel {
    page: string;
    value: number;
    discount: number;
    cashback: number;
}

export const IndexReducer = () => {
    const setContextRule = (model: EnergyStateModel, payload: PayloadAction<EnergyStateModel>) => model = payload.payload;

    const indexSlice = createSlice({
        name: 'energy',
        initialState: {} as EnergyStateModel,
        reducers: {
            setContextEnergy: (model: EnergyStateModel, payload: PayloadAction<EnergyStateModel>) => setContextRule(model, payload),
        }
    });

    return { indexSlice };
}

export const { indexSlice } = IndexReducer();
export const { setContextEnergy } = indexSlice.actions;

export default indexSlice.reducer;