import axios from "axios";

export const ColaboratorService = () => {
    const buscarIdpv = async (idColaborator: number) => {
        try {
            var response = await axios.get<any>(`${process.env.REACT_APP_API}/idpv/${idColaborator}`);
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    };

    return { buscarIdpv };
}