import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GasStateModel {
    page: string;
    frequency: number;
    value: number;
    discount: number;
    mounthDiscount: number;
}

export const IndexReducer = () => {
    const setContextRule = (model: GasStateModel, payload: PayloadAction<GasStateModel>) => model = payload.payload;

    const indexSlice = createSlice({
        name: 'gas',
        initialState: {} as GasStateModel,
        reducers: {
            setContextGas: (model: GasStateModel, payload: PayloadAction<GasStateModel>) => setContextRule(model, payload),
        }
    });

    return { indexSlice };
}

export const { indexSlice } = IndexReducer();
export const { setContextGas } = indexSlice.actions;

export default indexSlice.reducer;