import { formatValue } from "react-currency-input-field";
import { useAppSelector } from "./hooks";
import { RootState } from "./store";

import VanillaMasker from './internal-dependencies/vanilla-mask';

export const removeMask = (value: string) => {
    return value.replace(/\./g, "").replace(/_/g, "").replace(/-/g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/ /g, "").replace(/\//g, "");
}

export const validateCPF = (cpf: string) => {
    if (typeof cpf !== 'string') return false
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
    let cpfArray = cpf.split('').map((el: string) => +el)
    const rest = (count: number) => (cpfArray.slice(0, count - 12)
        .reduce((soma: number, el: number, index: number) => (soma + el * (count - index)), 0) * 10) % 11 % 10
    return rest(10) === cpfArray[9] && rest(11) === cpfArray[10]
}

export const validateEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const clearCurrency = (value?: string) => {
    value = value?.replace('R$ ', '');
    value = value?.replace(/\./g, '');
    return value ? Number.parseFloat(value.replace(",", ".")) : 0;
}

export const doubleToString = (value?: number) => {
    if (value) {
        var valueSlipted = value.toString();
        if(valueSlipted.indexOf('.') >= 0) {
            let newValue = valueSlipted.split('.');
            newValue[1] = newValue[1].padEnd(2, "0");
            valueSlipted = newValue.join(".");
        }
        else {
            valueSlipted = valueSlipted + '.00';
        }
        return valueSlipted;
    }

    return undefined;
}

export const StringToDouble = (value?: any) => {
    if (value) {
        var valueSlipted = value;
        if(valueSlipted.indexOf(',') >= 0) {
            let newValue = valueSlipted.split(',');
            newValue[1] = newValue[1].padEnd(2, "0");
            valueSlipted = newValue.join(".");
        }
        else {
            valueSlipted = valueSlipted + '.00';
        }
        return Number(valueSlipted);
    }

    return 0;
}

export const doubleToCurrency = (value: number) => {
    let newValue = formatValue({
        value: value.toFixed(2),
        intlConfig: { locale: 'pt-BR', currency: 'BRL' },
    });

    let valueSlipted = newValue.split(",");
    valueSlipted[1] = valueSlipted[1] ? valueSlipted[1].padEnd(2, "0") : "00";
    newValue = valueSlipted.join(",");

    return newValue;
}

export const focusOn = (event: React.KeyboardEvent<Element>, elementId: string) => {
    if (event.key === 'Enter')
        document.getElementById(elementId)?.focus();
}

export const doubleToPercent = (value: number) => {
    let newValue = parseFloat(value.toFixed(3));
    newValue = isNaN(newValue) ? 0 : parseFloat((value * 100).toFixed(3));

    return `${newValue}%`
};

export const GetAbrangencia = () => {
    const formClientState = (state: RootState) => state.formClient;
    const formClient = useAppSelector(formClientState);

    return formClient;
}

export function createCookie(cookieName: string, cookieValue: string, expireInDays: number) {
    var date = new Date();
    date.setDate(date.getDate() + expireInDays);
    document.cookie = cookieName + ' = ' + cookieValue + '; expires = ' + date.toUTCString();
    return true;
};

export function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function delCookie(cname: string) {
    document.cookie = `${cname}; Max-Age=0`;
}

export function getParam(value: string) {
    let params = window.location.search.replace("?", "").split("&");

    let tryParam = params.find(x => x.indexOf(value) > -1);
    let hasParam = tryParam ? tryParam.trim().length > 0 : false;
    let param: any;

    if (hasParam) {
        param = params.find(x => x.indexOf(value) > -1)!.replace(value, "");
    }

    return { value: param, has: hasParam };
}

export function removeWhiteSpaces(text: any) {
    return (text || '').replace(/\s/g, '');
}

export function removeNotNumbers(text: any) {
    return text.replace(/[^0-9]+/g, '');
}

export function maskVal(value: any) {
    const MONEY_MASK_SETTINGS = {
        precision: 2,
        separator: ',',
        delimiter: '.',
        unit: 'R$ ',
        suffixUnit: ''
    };

    let sanitized = removeWhiteSpaces(value);
    sanitized = removeNotNumbers(value);
    let masked: string = VanillaMasker.toMoney(sanitized, MONEY_MASK_SETTINGS);
    // console.log(masked);
    
    return masked;
}

export const dateComparePeriod = (startDate: string, endDate: string) => { //"date format: mm/dd/yyyy"
    const today = new Date();
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    today.setHours(0,0,0,0);

    const response = {
        insidePeriod: today >= date1 && today <= date2 ? true : false,
        laterDate: today > date2 ? true : false
    };
    
    return response;
};