import React from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

export interface ModelCashbackPartners {
    category: string;
    partners: ModelCashbackPartnersItems[];
}

export interface ModelCashbackPartnersItems {
    logo: string;
    description: string;
    readMore: string;
}

const CashbackPartnersComponent = (props: { partners: ModelCashbackPartners[] }) => {
    const { partners } = props;
    return (
        <>
        {partners.map((partner, index) => 
        <React.Fragment key={index}>
            <Row className="cb-partners-category mt-4 mb-3">
                <Col>
                    <h4>{partner.category}</h4>
                </Col>
            </Row>
        <div className="d-print-inline-flex">
        {partner.partners.map((partner, index) => 
            <React.Fragment key={index}>
                    <Row className="cb-partners-item">
                        <Col>
                            <Row className="align-items-center">
                                <Col className="col-12 col-lg-auto logo-box d-flex align-items-center justify-content-center mb-3 mb-lg-0">
                                    <img src={"./assets/img/partners/"+partner.logo} alt={partner.description} className="img-fluid" />
                                </Col>
                                <Col className="col-12 col-lg mb-3 mb-lg-0">
                                    <h4 className="ps-0 ps-lg-2" dangerouslySetInnerHTML={{ __html: partner.description }}></h4>
                                </Col>
                                <Col className="col-12 col-lg mb-3 mb-lg-0">
                                    {partner.readMore !== '#' && partner.readMore ? 
                                        <a href={partner.readMore} target="_blank" className="underline text-link no-print">Saiba mais</a>
                                        :
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Já solicitamos o link, em breve ele chega 👍</Tooltip>}>
                                            <span className="d-inline-block">
                                                <a href={partner.readMore} target="_blank" className="underline text-link no-print">Saiba mais</a>
                                            </span>
                                        </OverlayTrigger>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr className="cb-partners-separator my-4 no-print" />
            </React.Fragment>
        )}
        </div>
        </React.Fragment>
        )}
        </>
    );
}

export default CashbackPartnersComponent;