import CardDiscountComponent from "../../components/card-discount.component";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DropdownComponent from "../../components/dropdown.component";
import { doubleToCurrency, doubleToPercent } from "../../utils";
import { Logic } from "./logic";
import { useEffect } from "react";
import InputComponent from '../../components/input.component';

export default function Farmacy() {
    const { value, setValue, items, dropdownValue, handleBack, handleSubmit, verifyDiscount, getDiscountCard, getCashbackCard, enterAction, setDropDown, total, model, discount, cashback } = Logic();
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    return (
        <Container>
            <Row className="align-items-center">
                <Col className='col-lg-4 mt-4 mt-lg-0 order-1 order-lg-0'>
                    <Row className='mb-3 align-items-center'>
                        <Col className="col-auto">
                            <img src="/assets/img/icons/ic-calculadora.svg" alt="Ícone Calculadora" className="img-fluid " />
                        </Col >
                        <Col className="col-auto ms-2">
                            <img src="/assets/img/benefits/logo-drogasil.svg" alt="Farmácia Drogasil" />
                            <img src="/assets/img/benefits/logo-droga-raia.svg" alt="Farmácia Droga Raia" className="img-fluid ms-3" />
                        </Col>
                    </Row>
                    
                    <Row className='mb-4 page-title'>
                        <Col>
                            <h1 className="text-primary">
                                Quanto você gasta com 
                                <br /> 
                                farmácia por mês?
                            </h1>
                        </Col>
                    </Row>

                    <Form>
                        <Row className="mb-3">
                            <DropdownComponent
                                title="Tipo de produto"
                                value={dropdownValue ? items.find(x => x.key === dropdownValue)!.value : undefined}
                                setDropdownValue={(value: number) => setDropDown(value)}
                                items={items}
                            />
                            <InputComponent
                                title="Valor mensal (R$)"
                                type="text"
                                placeholder="R$ 00,00"
                                autoFocus={true}
                                value={value}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(val => val = event.target.value)}
                                onKeyUp={() => verifyDiscount()}
                                disabled={dropdownValue === undefined || dropdownValue === 0}
                                isValid={value !== undefined}
                                isInvalid={false}
                                enterKeyHint="enter"
                                onKeyDown={enterAction}
                            />
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <h4 className='title-detail'>
                                    Estimativa de economia 
                                </h4>
                            </Col>
                        </Row>
                        <Row className="pb-5">
                            <Col>
                                <table className='table-discounts w-100'>
                                    <tbody>
                                        <tr className='line-details'>
                                            <td className='info'>{doubleToPercent(model.items[dropdownValue! - 1]?.discount ?? 0)} de desconto*</td>
                                            <td className='value text-end'>{doubleToCurrency(discount)}</td>
                                        </tr>
                                        <tr className='line-details'>
                                            <td className='info'>{doubleToPercent(model.items[dropdownValue! - 1]?.cashback ?? 0)} de dinheiro de volta*</td>
                                            <td className='value text-end'>{doubleToCurrency(cashback)}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr className='line-total'>
                                            <td className='info'>Benefício mensal</td>
                                            <td className='value text-end'>{doubleToCurrency(total)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>

                        <Row className="mt-3 align-items-center">
                            <Col>
                                <a className="text-dark" onClick={handleBack}><u>Voltar</u></a>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button variant="secondary" size="sm" type="button" onClick={handleSubmit}>Próximo</Button>
                            </Col>  
                        </Row>
                    </Form>
                </Col>
                <Col lg={4} className="mb-sm-0 d-flex order-0 order-lg-1 px-0">
                    <CardDiscountComponent discount={getDiscountCard()} cashback={getCashbackCard()} />
                </Col>
                <Col lg={4} className="d-none d-lg-block text-center order-2">
                    <img src="./assets/img/banners/banner-farmacia.png" alt="Banner Farmácia" className='img-fluid'/>
                </Col>
            </Row>
            <Row className='pt-5'>
                <Col lg={4}>
                    <p className='text-muted'>
                        <i>
                        * Os descontos e cashback (bonificação) são válidos para o site e drogarias físicas em todo o território nacional, nas seguintes categorias:
                        </i>
                    </p>
                    <ul className='text-muted fst-italic'>
                        <li>
                            Tarja Marca: 15% de desconto e 2% de cashback (bonificação)
                        </li>
                        <li>
                            Tarja genérica: 35% de desconto e 10% de cashback (bonificação)
                        </li>
                        <li>
                            Medicamentos de prateleira - Marca: 5% de desconto e 4% de cashback (bonificação)
                        </li>
                        <li>
                            Medicamentos de prateleira - Genérico: 5% de desconto e 10% de cashback (bonificação)
                        </li>
                        <li>
                            Perfumaria: 5% de desconto e 4% de cashback (bonificação)
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}
