import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HealthStateModel {
    page: string;
    medicalSpecialty: number;
    value: number;
    discount: number;
}

export const IndexReducer = () => {
    const setContextRule = (model: HealthStateModel, payload: PayloadAction<HealthStateModel>) => model = payload.payload;

    const indexSlice = createSlice({
        name: 'health',
        initialState: {} as HealthStateModel,
        reducers: {
            setContextHealth: (model: HealthStateModel, payload: PayloadAction<HealthStateModel>) => setContextRule(model, payload),
        }
    });

    return { indexSlice };
}

export const { indexSlice } = IndexReducer();
export const { setContextHealth } = indexSlice.actions;

export default indexSlice.reducer;