import { removeMask, validateCPF, validateEmail, createCookie, getParam, getCookie, dateComparePeriod, doubleToCurrency } from "../../utils";
import { DropdownItem } from "../../components/dropdown.component";
import { setContext, FormClientStateModel } from "./reducer";
import { setContextEnergy, EnergyStateModel } from "../energy/reducer";
import { setContextFarmacy, FarmacyStateModel } from "../farmacy/reducer";
import { setContextGas, GasStateModel } from "../gas/reducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { setRedirect } from "../../reducer";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { CepService, HubspotService } from "../../services";
import { CepModel, HubspotModel } from "../../models";
import { VerificaAbrangencia } from "../../services/cep.service";
import { FiliadoService } from "../../services/filiado.service";
import { ColaboratorService } from "../../services/colaborator.service";
import { EmailService } from "../../services/email.service";

import configuration from '../../../configurations.json';


export const Logic = () => {
    sessionStorage.clear();
    localStorage.clear();

    if(!localStorage.getItem("mensalidade_cartao") && !localStorage.getItem("clinico_geral") && !localStorage.getItem("demais") ) {
        localStorage.setItem("mensalidade_cartao", '29,70');
        localStorage.setItem("clinico_geral", '26');
        localStorage.setItem("demais", '35');
    }
    
    const [initialized, setInitialized] = useState(false);
    const currentState = (state: RootState) => state.formClient;
    const state = useAppSelector(currentState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const isColaborator = state.ssoUserSub ? true : false;
    
    const [isColaboratorLead, setIsColaboratorLead] = useState(false);
    
    const colaboratorHasIdpv = state.ssoIdColaborator != 0 ? true : false;
    
    const monthly = doubleToCurrency(configuration.course.items[0].discountFix);

    const [loadingCep, alterarLoadingCep] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [loadingRegistration, alterarLoadingRegistration] = useState(false);
    const [loadingCPF, alterarLoadingCPF] = useState(false);
    const [loadingSaveForm, alterarLoadingSaveForm] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [newFeatures, setNewFeatures] = useState(false);
    const [modalUpdatesShow, setModalUpdatesShow] = useState(false);


    const [name, setName] = useState(state.name || "");
    const [validName, setValidName] = useState(false);
    const [invalidName, setInvalidName] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = useState("Campo obrigatório");

    function verifyName() {
        var hasNumber = /\d/;
        var message = nameErrorMessage;
        var invalid = name.length < 3 || hasNumber.test(name);

        setInvalidName(invalid);
        setValidName(name.length >= 3 && !hasNumber.test(name));

        if (name.length <= 0)
            message = "Campo obrigatório";
        else if (hasNumber.test(name)) {
            message = "Nome inválido";
            setInvalidName(true);
            setValidName(false);
        }
        else
            message = "Digite seu nome completo";


        setNameErrorMessage(message);
    }

    const [registration, setRegistration] = useState(state.registration || "");
    const [registrationAnterior, setRegistrationAnterior] = useState("");
    const [validRegistration, setValidRegistration] = useState(false);
    const [invalidRegistration, setInvalidRegistration] = useState(false);
    const [registrationErrorMessage, setRegistrationErrorMessage] = useState("Campo obrigatório");
    async function verifyRegistration() {
        setInvalidRegistration(false);
        setValidRegistration(true);
        if(registration.length >= 8 && registration !== registrationAnterior) {
            alterarLoadingRegistration(true);
            var resp: any = await FiliadoService().buscarFiliado(registration);
            
            if(registration.length > 0 && resp.quantidade > 0) {

                const t = resp.dados.findIndex((x:any) => x.tipoFiliado.toLowerCase() === 'titular');
    
                if(resp.dados[t].statusFiliado.toLowerCase() === 'ativo') {
                    setRegistrationAnterior(registration);

                    setName(!name && name !== null && resp.dados[t].nomeFiliado ? resp.dados[t].nomeFiliado : name);
                    setEmail(!email && email !== null && resp.dados[t].email ? resp.dados[t].email : email);
                    setCpf(!cpf && cpf !== null && resp.dados[t].cpf ? resp.dados[t].cpf : cpf);
                    setCpfAnterior(!cpf && cpf !== null && resp.dados[t].cpf ? resp.dados[t].cpf : cpf);
                    setCep(!cep && cep !== null && resp.dados[t].cep ? resp.dados[t].cep : cep);
                    verifyCep(!cep && cep !== null && resp.dados[t].cep ? resp.dados[t].cep : cep);
                    setPhone(!phone && phone !== null && resp.dados[t].celular ? resp.dados[t].celular : phone);
                    setFranchise(!franchise && franchise !== null && resp.dados[t].nomeFranquia ? resp.dados[t].nomeFranquia : franchise);
                    setFranchiseId(!franchiseId && franchiseId !== null && resp.dados[t].idFranquia ? resp.dados[t].idFranquia : franchiseId);
                    setRegional(!regional && regional !== null && resp.dados[t].regionalNome ? resp.dados[t].regionalNome : regional);
                    
                    setInvalidRegistration(false);
                    setValidRegistration(true);
                }
                else {
                    setRegistrationErrorMessage(`A Matrícula está ${resp.dados[t].statusFiliado}`)
                    setInvalidRegistration(true);
                    setValidRegistration(false);
                }

            }
            else {
                setRegistrationErrorMessage(resp.mensagem)
                
                setInvalidRegistration(true);
                setValidRegistration(false);
            }
            alterarLoadingRegistration(false);
        }
    }

    const [cpf, setCpf] = useState(state.cpf || "");
    const [cpfAnterior, setCpfAnterior] = useState("");
    const [validCpf, setValidCpf] = useState(false);
    const [invalidCpf, setInvalidCpf] = useState(false);
    const [cpfErrorMessage, setCpfErrorMessage] = useState("Campo obrigatório");
    async function verifyCpf() {
        var cpfClear = removeMask(cpf);
        var cpfValido: boolean = validateCPF(cpfClear);
        setCpf(value => value = cpfClear);

        setCpfErrorMessage(!cpfValido && cpfClear.length > 0 ? 'CPF inválido' : 'Campo obrigatório');
        setInvalidCpf(!cpfValido && cpfClear.length > 0);
        setValidCpf(cpfValido && cpfClear.length > 0);

        if(isColaborator) {

            if (cpfValido) {
    
                if(cpfClear !== cpfAnterior) {
                    alterarLoadingCPF(true);
    
                    var resp: any = await FiliadoService().buscarFiliado(cpfClear);
                    if(resp.quantidade > 0) {

                        const t = resp.dados.findIndex((x:any) => x.tipoFiliado.toLowerCase() === 'titular');
            
                        if(resp.dados[t].statusFiliado.toLowerCase() === 'ativo') {
                            setCpfAnterior(cpfClear);
        
                            setName(!name && name !== null && resp.dados[t].nomeFiliado ? resp.dados[t].nomeFiliado : name);
                            setEmail(!email && email !== null && resp.dados[t].email ? resp.dados[t].email : email);
                            setRegistration(!registration && registration !== null && resp.dados[t].matricula ? resp.dados[t].matricula : registration);
                            setRegistrationAnterior(!registration && registration !== null && resp.dados[t].matricula ? resp.dados[t].matricula : registration);
                            setCep(!cep && cep !== null && resp.dados[t].cep ? resp.dados[t].cep : cep);
                            verifyCep(!cep && cep !== null && resp.dados[t].cep ? resp.dados[t].cep : cep);
                            setPhone(!phone && phone !== null && resp.dados[t].celular ? resp.dados[t].celular : phone);
                            setFranchise(!franchise && franchise !== null && resp.dados[t].nomeFranquia ? resp.dados[t].nomeFranquia : franchise);
                            setRegional(!regional && regional !== null && resp.dados[t].regionalNome ? resp.dados[t].regionalNome : regional);
                            
                            setInvalidCpf(false);
                            setValidCpf(true);
                        }
                        else {
                            setCpfErrorMessage(`O status do filiado está ${resp.dados[t].statusFiliado}`);
                            setInvalidCpf(true);
                            setValidCpf(false);
                        }
        
                    }
                    else {
                        setCpfAnterior(cpfClear);
                        setInvalidCpf(false);
                        setValidCpf(true);                        
                        setIsColaboratorLead(true);
                        setInvalidRegistration(false);
                        setValidRegistration(true);
                        setInvalidFranchise(false);
                        setValidFranchise(true);
                    }
                    alterarLoadingCPF(false);
                }
    
            }
            else {
                if (cpfClear.length > 0)
                    setCpfErrorMessage('CPF inválido')
    
                setInvalidCpf(true);
                setValidCpf(false);
            }
        }
    }

    const [franchise, setFranchise] = useState(state.franchise || "");
    const [franchiseId, setFranchiseId] = useState(state.franchiseId || "");
    const [regional, setRegional] = useState(state.regional || "");
    const [validFranchise, setValidFranchise] = useState(false);
    const [invalidFranchise, setInvalidFranchise] = useState(false);
    const [franchiseErrorMessage] = useState("Campo obrigatório");
    function verifyFranchise() {
        if(!isColaboratorLead) {
            setInvalidFranchise(franchise.length < 1);
            setValidFranchise(franchise.length >= 1);
        }
    }

    const [attendantName, setAttendantName] = useState(state.ssoUserName || "");
    const [validAttendantName, setValidAttendantName] = useState(false);
    const [invalidAttendantName, setInvalidAttendantName] = useState(false);
    const [attendantNameErrorMessage] = useState("Campo obrigatório");
    function verifyAttendantName() {
        setInvalidAttendantName(attendantName.length < 1);
        setValidAttendantName(attendantName.length >= 1);

    }

    const [linkColaborator, setLinkColaborator] = useState(state.linkColaborator || "");
    const [idpv, setIdpv] = useState(state.idpv || "");
    const [loadingIdpv, setloadingIdpv] = useState(false);
    async function getIdpv(idColaborator: number) {
        setloadingIdpv(true);
        var resp: any = await ColaboratorService().buscarIdpv(idColaborator);
        if(resp.dados.idpv) {
            setIdpv(resp.dados.idpv);
            setLinkColaborator(`${window.location.origin}?idpv=${resp.dados.idpv}`);
        }
        setloadingIdpv(false);
    }
    useEffect(() => {
        if(state.ssoIdColaborator && state.ssoIdColaborator != 0 && linkColaborator === "") {
            getIdpv(state.ssoIdColaborator);
        }
    }, [getIdpv]);

    const [email, setEmail] = useState(state.email || "");
    const [validEmail, setValidEmail] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("Campo obrigatório");
    async function verifyEmail() {
    
        var eValido: boolean = validateEmail(email);
        setInvalidEmail((value) => value = !eValido);
        setValidEmail((value) => value = eValido);
        var message = "Campo obrigatório";

        if (email.length <= 0)
            setEmailErrorMessage(message);
        else {
            if (!eValido) {
                message = "E-mail inválido";
                setEmailErrorMessage(message);
            }
        }

        if (eValido && !getCookie('_idpv')) {
            setLoadingEmail(true);
            var emailResp: any = await EmailService().validarEmail(email);
            if (emailResp.idpv != '-') {
                createCookie("_idpv", emailResp.idpv, 14);
            }
            setLoadingEmail(false);
        }

    }

    const [cep, setCep] = useState(state.cep || "");
    const [address, setAddress] = useState(new CepModel());
    const [cepAnterior, setCepAnterior] = useState("");
    const [uf, setUf] = useState(state.uf || "");
    const [bairro, setBairro] = useState(state.bairro || "");
    const [cidade, setCidade] = useState(state.cidade || "");

    const [atendeEnergiaTodos, setAtendeEnergiaTodos] = useState(state.atendeEnergiaTodos || false);
    const [atendeFarmacia, setAtendeFarmacia] = useState(state.atendeFarmacia || false);
    const [atendeGas, setAtendeGas] = useState(state.atendeGas || false);

    const [validCep, setValidCep] = useState(false);
    const [invalidCep, setInvalidCep] = useState(false);
    const [cepErrorMessage, setCepErrorMessage] = useState("Campo obrigatório");
    async function verifyCep(cep: string) {
        var cepTratado = removeMask(cep);
        setCep(value => value = cepTratado);

        if (cepTratado.length <= 0) {
            setInvalidCep(true);
            setValidCep(false);
            setCepErrorMessage("Campo obrigatório");
        }
        else if (cepTratado.length < 8) {
            setInvalidCep(true);
            setValidCep(false);
            setCepErrorMessage("Formato de CEP inválido");
        }
        else {
            // Busca CEP e seta variáveis
            if(cepTratado !== cepAnterior) {

                alterarLoadingCep(true);
                var resp: CepModel | any = await CepService().buscarCep(cepTratado);

                if(resp) {
                    setAddress(resp);

                    if(resp.message) {
                        setInvalidCep(true);
                        setValidCep(false);
                        setCepErrorMessage(resp.message);
                    }
    
                    else {
                        setCepAnterior(cepTratado);
                        setUf(resp.uf);
                        setBairro(resp.bairro);
                        setCidade(resp.cidade);
                        
                        // Filtra a abrangência para os produtos 7 estrelas
                        var abrangencia = await VerificaAbrangencia().filtrarJson(resp);
                        setAtendeEnergiaTodos(abrangencia.atendeEnergiaTodos);
                        setAtendeFarmacia(abrangencia.atendeFarmacias);
                        setAtendeGas(abrangencia.atendeGas);
            
                        if (!abrangencia.atendeEnergiaTodos)
                            dispatch(setContextEnergy({ page: 'energy',value: 0, discount: 0, cashback: 0 } as EnergyStateModel));
                        if (!abrangencia.atendeFarmacias)
                            dispatch(setContextFarmacy({ page: 'farmacy', value: 0, product: 0, discount: 0, cashback: 0 } as FarmacyStateModel));
                        if (!abrangencia.atendeGas)
                            dispatch(setContextGas({ page: 'gas', value: 0, frequency: 0, discount: 0, cashback: 0, mounthDiscount: 0 } as GasStateModel));
                        
                        setInvalidCep(false);
                        setValidCep(true);
                    }
                }
                
                alterarLoadingCep(false);
            }
        }
    }

    const [phone, setPhone] = useState(state.phone || "");
    const [validPhone, setValidPhone] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("Campo obrigatório");
    function verifyPhone() {
        var phoneClear = removeMask(phone);
        var messasge = phoneErrorMessage;

        setPhone(value => value = phoneClear);
        setInvalidPhone(phoneClear.length < 11);
        setValidPhone(phoneClear.length >= 11);

        if (phoneClear.length <= 0) {
            messasge = "Campo obrigatório";
            setPhoneErrorMessage(messasge);
        }
        else {
            messasge = "Telefone inválido";
            setPhoneErrorMessage(messasge);
        }
    }

    const [terms, setTerms] = useState(state.terms || false);
    const [validTerms, setValidTerms] = useState(false);
    const [invalidTerms, setInvalidTerms] = useState(false);
    const [termsErrorMessage, setTermsErrorMessage] = useState("Campo obrigatório");
    function verifyTerms() {
        setInvalidTerms(terms === false);
        setValidTerms(terms === true);

        if (!terms)
            setTermsErrorMessage("Campo obrigatório");
    }

    const [termsWhatsapp, setTermsWhatsapp] = useState(state.termsWhatsapp || false);
    const [termsSMS, setTermsSMS] = useState(state.termsSMS || false);
    const [termsEmail, setTermsEmail] = useState(state.termsEmail || false);

    const items = [
        new DropdownItem(1, "Sim"),
        new DropdownItem(2, "Não"),
    ];

    const verifyForm = () => {
        verifyName();
        verifyEmail();
        verifyCep(cep);
        verifyPhone();
        verifyTerms();
        if(isColaborator) {
            verifyCpf();
            verifyRegistration();
            verifyFranchise();
            verifyAttendantName();
        }


        // setShowDropdownRequired(dropdownValue === undefined)
    }

    const handleSubmit = () => {
        verifyForm();
        sendForm();
    }

    if (!initialized && state.terms) {
        setInitialized(value => value = true);
        verifyForm();
    }

    const enterAction = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    async function sendForm() {
        alterarLoadingSaveForm(true);
        var explodeName = name.split(/ (.*)/s);        
        var form = {
            hbs: {
                firstname: explodeName[0],
                lastname: explodeName[1],
                cep: cep,
                estado: address.uf,
                rua: address.logradouro,
                bairro: address.bairro,
                cidade: address.cidade,
                franquiactn: franchiseId ? franchiseId : address.franquiactn,
                email: email,
                phone: phone,
                mobilephone: phone,
                cpf: cpf,
                id_promotor_lead: idpv ? idpv : getCookie('_idpv'),
                hs_legal_basis: terms ? 'Freely given consent from contact' : '',
                opt_in_whatsapp: termsWhatsapp,
                whatsapp_optin: termsWhatsapp,
                sms_optin: termsSMS,
                aceita_contato_por_whatsapp: termsWhatsapp,
                auditoria_e_mail: email ? 'Possui E-mail' : 'Não Possui E-mail',
                status_de_envio_sms: termsSMS ? 'Enviado' : ''
            } as HubspotModel
        };
        
            if(isColaborator) {
                if (validName && validEmail && validCep && validPhone && validTerms && validCpf && validRegistration && validFranchise && validAttendantName) {
                    await HubspotService().sendLead(form);
                    dispatch(setContext({ 
                        cep, 
                        cpf, 
                        email, 
                        name, 
                        registration, 
                        franchiseId: franchiseId ? franchiseId : address.franquiactn,
                        nearestFranchise: registration ? 'nao_obrigatorio' : address.franquiaNome,
                        franchise: franchise ? franchise : address.franquiaNome,
                        regional: regional ? regional : address.regionalNome,
                        attendantName, 
                        phone, 
                        ssoUserSub: state.ssoUserSub, 
                        ssoUserName: state.ssoUserName, 
                        ssoUserEmail: state.ssoUserEmail, 
                        terms, 
                        termsWhatsapp, 
                        termsSMS, 
                        termsEmail, 
                        uf, 
                        bairro, 
                        cidade, 
                        atendeEnergiaTodos, 
                        atendeFarmacia, 
                        atendeGas,
                        isColaboratorLead: isColaboratorLead,
                        linkColaborator,
                        idpv: idpv ? idpv : getCookie('_idpv'),
                    } as FormClientStateModel));
                    
                    dispatch(setRedirect({ redirect: true }));       
                    let nextRoute = "/";
                    if (atendeEnergiaTodos)
                        nextRoute = "/energy";
                    else if (atendeFarmacia)
                        nextRoute = "/farmacy";
                    else if (atendeGas)
                        nextRoute = "/gas";
                    else
                        nextRoute = "/phone";
                    navigate(nextRoute + window.location.search);
                }
            }
            else {
                if (validName && validEmail && validCep && validPhone && validTerms) {
                    await HubspotService().sendLead(form);
                    dispatch(setContext({ 
                        cep, 
                        cpf, 
                        email, 
                        name, 
                        phone,
                        nearestFranchise: address.franquiaNome,
                        regional: regional ? regional : address.regionalNome,
                        terms, 
                        termsWhatsapp, 
                        termsSMS, 
                        termsEmail, 
                        uf,
                        bairro, 
                        cidade, 
                        atendeEnergiaTodos, 
                        atendeFarmacia, 
                        atendeGas,
                        idpv: idpv ? idpv : getCookie('_idpv')
                    } as FormClientStateModel));
                    
                    dispatch(setRedirect({ redirect: true }));       
                    let nextRoute = "/";
                    if (atendeEnergiaTodos)
                        nextRoute = "/energy";
                    else if (atendeFarmacia)
                        nextRoute = "/farmacy";
                    else if (atendeGas)
                        nextRoute = "/gas";
                    else
                        nextRoute = "/phone";
                    navigate(nextRoute + window.location.search);
                }
            }
            alterarLoadingSaveForm(false);
    }

    return {
        name, setName, verifyName, validName, invalidName, nameErrorMessage,
        registration, setRegistration, verifyRegistration, validRegistration, invalidRegistration, registrationErrorMessage,
        franchise, setFranchise, verifyFranchise, validFranchise, invalidFranchise, franchiseErrorMessage,
        attendantName, setAttendantName, verifyAttendantName, validAttendantName, invalidAttendantName, attendantNameErrorMessage,
        linkColaborator, setLinkColaborator,
        monthly,
        email, setEmail, verifyEmail, validEmail, invalidEmail, emailErrorMessage,
        cep, setCep, verifyCep, validCep, invalidCep, cepErrorMessage,
        phone, setPhone, verifyPhone, validPhone, invalidPhone, phoneErrorMessage,
        cpf, setCpf, verifyCpf, validCpf, invalidCpf, cpfErrorMessage,
        terms, setTerms, verifyTerms, validTerms, invalidTerms, termsErrorMessage,
        termsWhatsapp, setTermsWhatsapp, termsSMS, setTermsSMS, termsEmail, setTermsEmail,
        // dropdownValue, setDropdownValue, items, showDropdownRequired,
        handleSubmit, enterAction, loadingCep, loadingEmail, loadingRegistration, loadingCPF, loadingSaveForm, modalShow, setModalShow, modalUpdatesShow, setModalUpdatesShow,
        isColaborator, isColaboratorLead, colaboratorHasIdpv, idpv,
        loadingIdpv
    };
}
