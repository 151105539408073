import axios from "axios";

export const FiliadoService = () => {
    const buscarFiliado = async (matricula: string) => {
        try {
            var response = await axios.get<any>(`${process.env.REACT_APP_API}/filiado/${matricula}`);
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    };

    return { buscarFiliado };
}