import { createSlice } from "@reduxjs/toolkit";
export interface FormClientStateModel {
    value: number;
    status: string;
    nome: string;
    email: string;
}

export const IndexReducer = () => {
    const indexSlice = createSlice({
        name: 'total',
        initialState: {} as FormClientStateModel,
        reducers: {}
    });

    return { indexSlice };
}

export const { indexSlice } = IndexReducer();

export default indexSlice.reducer;