import { useAppDispatch, useAppSelector } from "../../hooks";
import { clearCurrency, doubleToString, GetAbrangencia, maskVal } from "../../utils";
import { EnergyStateModel, setContextEnergy } from "./reducer";
import configuration from '../../../configurations.json';
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { setRedirect } from "../../reducer";

export const Logic = () => {
    const [initialized, setInitialized] = useState(false);
    const currentState = (state: RootState) => state.energy;
    const state = useAppSelector(currentState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const abrangencia = GetAbrangencia();

    const formClientState = (state: RootState) => state.formClient;
    const formClient = useAppSelector(formClientState);
    const isColaborator = formClient.ssoUserSub ? true : false;

    const [value, setValue] = useState(doubleToString(state.value) || '');
    const [discount, setDiscount] = useState(state.discount || 0);
    const [cashback, setCashback] = useState(state.cashback || 0);
    const [total, setTotal] = useState(0);
    function verifyDiscount() {

        let variationKwh = 1.12;
        
        if(clearCurrency(value) >= 35) {

            let valueToCalc = Number((((clearCurrency(value) / variationKwh) - 30) * variationKwh).toFixed(2));
            let desc = Number((valueToCalc * configuration.energy.discount).toFixed(2));
            let cash = Number((valueToCalc * configuration.energy.cashback).toFixed(2));
    
            setDiscount(val => val = desc);
            setCashback(val => val = cash);
    
            setTotal(desc + cash);
        }
        else {
            setDiscount(0);
            setCashback(0);
            setTotal(0);
        }
    }

    const handleBack = () => {
        dispatch(setRedirect({ redirect: true }));
        var path = isColaborator ? "/colaborador" : "/";
        navigate(path + window.location.search)
    }

    const handleSubmit = () => {
        dispatch(setContextEnergy({ page: 'energy', value: clearCurrency(value), discount, cashback } as EnergyStateModel));
        dispatch(setRedirect({ redirect: true }));
        
        let nextRoute = "/energy";
        if (abrangencia.atendeFarmacia)
            nextRoute = "/farmacy";
        else if (abrangencia.atendeGas)
            nextRoute = "/gas";
        else
            nextRoute = "/phone";
        navigate(nextRoute + window.location.search);
    }

    if (!initialized && state.value) {
        setInitialized(value => value = true);
        verifyDiscount();
        setTotal(discount + cashback);
    }

    const enterAction = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    useEffect(() => {
        if(value) {
            setValue(maskVal(value));
            verifyDiscount();
        }
    }, [value, setValue]);

    return { handleBack, handleSubmit, value, setValue, verifyDiscount, discount, cashback, enterAction, total, model: configuration.energy };
}
