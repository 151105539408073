import { Card, Col, Row } from "react-bootstrap";
import { dateComparePeriod, doubleToCurrency, StringToDouble } from "../utils";
import { ReactComponent as IconLike } from '../assets/img/icons/ic-like.svg';
import { createCookie, getCookie, delCookie } from '../utils';
import { useEffect, useState } from "react";

const CardDiscountComponent = (props: { discount: number, cashback: number }) => {
    const { discount, cashback } = props;
    const [valorCartao, setValorCartao] = useState(0);
    useEffect(() => {
        if(valorCartao === 0 && localStorage.getItem('mensalidade_cartao')) {
            setValorCartao(StringToDouble(localStorage.getItem("mensalidade_cartao")));
        }
    }, [valorCartao]);
    
    const getCondition = (): boolean => {
        if(cashback >= valorCartao || discount >= valorCartao)
            return true;
        else
            return false;
    }

    const isAnimate = () => {
        if( getCondition() ) {
            
            var animated = sessionStorage.getItem("animate") ? true : false;
            if(!animated) {
                sessionStorage.setItem("animate", "true");
                return true
            }
            return false;
        }
        sessionStorage.clear();
        return false;
    }

    if(!getCondition())
        sessionStorage.clear();

    return (
        <Card className="discount-card ms-lg-auto">
            {!getCondition() ?
                <Card.Header className="bg-text-link text-white">
                    <Row>
                        <Col className="col detail">Mensalidade <b>Cartão de TODOS</b></Col>
                        <Col className="col-auto fw-bold text-end value">{doubleToCurrency(valorCartao)}</Col>
                    </Row>
                </Card.Header>
                :
                <Card.Header className="bg-secondary text-white">
                    {isAnimate() ?
                        <img src="./assets/img/misc/party.png" className="card-party" alt="Party" />
                        :
                        <></>
                    }
                    <Row className="position-relative">
                        <div className="col-auto float-icon">
                            <IconLike stroke="white"/>
                        </div>
                        <Col className="detail text-center">
                            Você já economizou mais do que 
                            <br />
                            investe no <b>Cartão de TODOS</b>!
                        </Col>
                    </Row>
                </Card.Header>
            }
            <Card.Footer className="bg-light">
                <div className="d-flex flex-row flex-lg-column align-items-stretch justify-content-around">
                    <Row className="flex-column flex-lg-row">
                        <Col sm={12} lg={'auto'} className="detail text-center text-lg-start mb-1 mb-lg-0 text-nowrap">Dinheiro de volta acumulado</Col>
                        <Col className="fw-bold text-center text-lg-end value">{doubleToCurrency(cashback)}</Col>
                    </Row>
                    <hr className="separator" />
                    <Row className="flex-column flex-lg-row">
                        <Col sm={12} lg={'auto'} className="detail text-center text-lg-start mb-1 mb-lg-0 text-nowrap">Desconto acumulado</Col>
                        <Col className="fw-bold text-center text-lg-end value">{doubleToCurrency(discount)}</Col>
                    </Row>
                </div>

            </Card.Footer>
        </Card>
    );
};

export default CardDiscountComponent;