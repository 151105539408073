import { BrowserRouter, Navigate, Route, Routes as DomRoutes } from 'react-router-dom';
import FormClient from './app/pages/form-client';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Farmacy from './app/pages/farmacy';
import Receipt from './app/pages/receipt';
import Course from './app/pages/course';
import Energy from './app/pages/energy';
import Health from './app/pages/health';
import { RootState } from './app/store';
import Total from './app/pages/total';
import Gas from './app/pages/gas';
import { useState } from 'react';
import { AuthService } from './react-oauth2-pkce/Index';
import { setContext, FormClientStateModel } from "./app/pages/form-client/reducer";



function Routes() {
    const appState = (state: RootState) => state.app;
    const app = useAppSelector(appState);
    const [canAccess, setCanAccess] = useState(false);
    const dispatch = useAppDispatch();

    const authService = new AuthService({
        clientId: `${process.env.REACT_APP_SSO_CLIENT_ID}`,
        provider: `${process.env.REACT_APP_SSO_BASE_URL}/connect`,
        redirectUri: `${process.env.REACT_APP_SSO_REDIRECT_URI}`,
        scopes: ['openid', 'profile', 'ctn'],
        durableLogin: process.env.REACT_APP_SSO_DURABLE_LOGIN === 'true',
    });
    
    function verifyAccess() {
        var path = window.location.pathname;
        var storeAuth: any = window.localStorage.getItem("auth");
        if (storeAuth) {
            storeAuth = JSON.parse(storeAuth);
        }
        
        if(path.indexOf('/colaborador') >= 0) {
            const login = async () => authService.authorize();
            const logout = async () => authService.logout();
            
            if (authService.isAuthenticated() && storeAuth && storeAuth.scope !== "openid profile ctn") {
                logout();
            }

            if (authService.isPending()) {
                console.log('authService.isPending()', authService.isPending());
                return false;
            }
            if (!authService.isAuthenticated()) {
                setCanAccess(false);
                login();
            }

            setCanAccess(true);
            const user = authService.getUser();
            dispatch(
                setContext(
                    { 
                        ssoUserSub: user?.sub, 
                        ssoUserName: user?.name, 
                        ssoUserEmail: user?.email,
                        ssoIdColaborator: user?.id_promotorvendas
                    } as FormClientStateModel
                )
            );
        }
    };

    return (
        <BrowserRouter>
            <DomRoutes>
                
                <Route element={canAccess ? <FormClient /> : <>{verifyAccess()}Carregando...</>} path="/colaborador" />
                <Route element={<FormClient />} path="/" />

                <Route element={app.redirect ? <Energy /> : <Navigate to="/" />} path="/energy" />
                <Route element={app.redirect ? <Farmacy /> : <Navigate to="/" />} path="/farmacy" />
                <Route element={app.redirect ? <Gas /> : <Navigate to="/" />} path="/gas" />
                {/* <Route element={app.redirect ? <Phone /> : <Navigate to="/" />} path="/phone" /> */}
                <Route element={app.redirect ? <Course /> : <Navigate to="/" />} path="/course" />
                <Route element={app.redirect ? <Health /> : <Navigate to="/" />} path="/health" />
                <Route element={app.redirect ? <Total /> : <Navigate to="/" />} path="/total" />

                <Route element={<Receipt />} path="/receipt" />
            </DomRoutes>
        </BrowserRouter>
    )
}

export default Routes