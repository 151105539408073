import CardDiscountComponent from "../../components/card-discount.component";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DropdownComponent from "../../components/dropdown.component";
import { doubleToCurrency } from "../../utils";
import { Logic } from "./logic";
import { useEffect } from "react";
import InputComponent from "../../components/input.component";

export default function Course() {
    const { items, dropdownValue, value, setValue, monthly, setMonthly, verifyDiscount, handleBack, handleSubmit, discount, getDiscountCard, getCashbackCard, enterAction, total, setDropDown, model } = Logic();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <Row className="align-items-center">
                <Col className='col-lg-4 mt-4 mt-lg-0 order-1 order-lg-0'>
                    <Row className='mb-3 align-items-center'>
                        <Col className="col-auto">
                            <img src="/assets/img/icons/ic-calculadora.svg" alt="Ícone Calculadora" />
                        </Col >
                        <Col className="col-auto ms-2">
                            <img src="/assets/img/benefits/logo-refuturiza.svg" alt="Refuturiza" />
                        </Col>
                    </Row>

                    <Row className='mb-4 page-title'>
                        <Col>
                            <h1 className="text-primary">
                                Você já faz ou pretende
                                <br />
                                iniciar algum curso?
                            </h1>
                        </Col>
                    </Row>

                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <DropdownComponent
                                    title="Selecione a área de estudo"
                                    value={dropdownValue ? items.find(x => x.key === dropdownValue)!.value : undefined}
                                    setDropdownValue={(value: number) => setDropDown(value)}
                                    items={items}
                                />
                                <InputComponent
                                    title="Valor mensal (R$)"
                                    type="text"
                                    placeholder="R$ 00,00"
                                    value={value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
                                    onKeyUp={() => verifyDiscount()}
                                    disabled={dropdownValue === undefined || dropdownValue === 0}
                                    isValid={value !== undefined}
                                    isInvalid={false}
                                    enterKeyHint="enter"
                                    onKeyDown={enterAction}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <h4 className='title-detail-green fw-bold pb-2'>
                                    *{monthly} com 100% de cashback na 1ª mensalidade!
                                </h4>
                                <h4 className='title-detail fw-normal'>
                                    Plataforma de Cursos Online e Vagas de emprego.
                                    <br />
                                    <b>Mais de 1.300 cursos com certificado</b>
                                    <br />
                                    reconhecido em 45 áreas de conhecimento.
                                </h4>
                            </Col>
                        </Row>
                        <Row className="pb-5">
                            <Col>
                                <hr className="my-0" />
                                <table className='table-discounts w-100'>
                                    <tfoot>
                                        <tr className='line-total'>
                                            <td className='info'>Benefício mensal</td>
                                            <td className='value text-end'>{doubleToCurrency(total)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>

                        <Row className="mt-3 align-items-center">
                            <Col>
                                <a className="text-dark" onClick={handleBack}><u>Voltar</u></a>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button variant="secondary" size="sm" type="button" onClick={handleSubmit}>Próximo</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col lg={4} className="mb-sm-0 d-flex order-0 order-lg-1 px-0">
                    <CardDiscountComponent discount={getDiscountCard()} cashback={getCashbackCard()} />
                </Col>
                <Col lg={4} className="d-none d-lg-block text-center order-2">
                    <img src="./assets/img/banners/banner-cursos.png" alt="Banner Refuturiza" className='img-fluid' />
                </Col>
            </Row>
            <Row className='pt-5'>
                <Col lg={4}>
                    <p className='text-muted'>
                        <i>
                            * Para clientes que possuem o Cartão de TODOS, o valor é de {monthly} por mês. Para clientes que ainda não se tornaram filiados do Cartão de TODOS, o valor é de R$39,99 por mês.
                        </i>
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
