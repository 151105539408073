import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppStateModel {
    redirect: boolean;
}

export const IndexReducer = () => {
    const setRedirectRule = (model: AppStateModel, payload: PayloadAction<AppStateModel>) => model = payload.payload;

    const indexSlice = createSlice({
        name: 'application',
        initialState: { redirect: false } as AppStateModel,
        reducers: {
            setRedirect: (model: AppStateModel, payload: PayloadAction<AppStateModel>) => setRedirectRule(model, payload),
        }
    });

    return { indexSlice };
}

export const { indexSlice } = IndexReducer();
export const { setRedirect } = indexSlice.actions;

export default indexSlice.reducer;