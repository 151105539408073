import { CepModel } from "../models";
import axios from "axios";
import abrangenciaEnergia from "../assets/json/energia-todos.json";
import abrangenciaFarmacia from "../assets/json/farmacias.json";
import abrangenciaGas from "../assets/json/ultragaz.json";

export const CepService = () => {
    const buscarCep = async (cep: string) => {
        try {
            var response = await axios.post<CepModel>(`${process.env.REACT_APP_API}/validate/cep/${cep}`);
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    };

    return { buscarCep };
}

export const VerificaAbrangencia = () => {
    const filtrarJson = async (address: CepModel) => {
        try {
            //Filtra lista da Energia de TODOS
            var listUFEnergia = await abrangenciaEnergia.filter( x => x.uf.toLowerCase() === address.uf?.toLowerCase() );
            var listCidadeEnergia = await listUFEnergia.filter( x => x.cidade.toLowerCase() === address.cidade?.toLowerCase() );

            //Filtra lista de farmácias
            var listUFFarmacia = await abrangenciaFarmacia.filter( x => x.uf.toLowerCase() === address.uf?.toLowerCase() );
            var listCidadeFarmacia = await listUFFarmacia.filter( x => x.cidade.toLowerCase() === address.cidade?.toLowerCase() );

            //Filtra lista da Ultragaz
            var listUFGas = await abrangenciaGas.filter( x => x.uf.toLowerCase() === address.uf?.toLowerCase() );
            var listCidadeGas = await listUFGas.filter( x => x.cidade.toLowerCase() === address.cidade?.toLowerCase() );
            // var listBairroGas = address.bairro ? await listCidadeGas.filter( x => x.bairro.toLowerCase() === address.bairro?.toLowerCase() ) : listCidadeGas;

            return {
                atendeEnergiaTodos: listCidadeEnergia.length > 0 ? true : false,
                atendeFarmacias: listCidadeFarmacia.length > 0 ? true : false,
                atendeGas: listCidadeGas.length > 0 ? true : false
            };
        } catch (error: any) {
            return error.response.data;
        }
    };

    return { filtrarJson };
}