import CardDiscountComponent from '../../components/card-discount.component';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { doubleToCurrency, doubleToPercent } from '../../utils';
import { Logic } from './logic';
import { useEffect } from 'react';
import InputComponent from '../../components/input.component';

export default function Energy() {
    const { handleBack, handleSubmit, value, setValue, verifyDiscount, discount, cashback, enterAction, total, model } = Logic();
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <Row className="align-items-center">
                <Col className='col-lg-4 mt-4 mt-lg-0 order-1 order-lg-0'>
                    <Row className='mb-3 align-items-center'>
                        <Col className="col-auto">
                            <img src="/assets/img/icons/ic-calculadora.svg" alt="Ícone Calculadora" />
                        </Col >
                        <Col className="col-auto ms-2">
                            <img src="/assets/img/benefits/logo-energia-todos.svg" alt="Energia de Todos" />
                        </Col>
                    </Row>
                    
                    <Row className='mb-4 page-title'>
                        <Col>
                            <h1 className="text-primary">
                                Quanto você gasta com
                                <br /> 
                                energia elétrica por mês?
                            </h1>
                        </Col>
                    </Row>

                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <InputComponent
                                    autoFocus={true}
                                    title="Valor mensal (R$)"
                                    type="text"
                                    placeholder="R$ 00,00"
                                    value={value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
                                    onKeyUp={verifyDiscount}
                                    isValid={value !== undefined}
                                    isInvalid={false}
                                    enterKeyHint="enter"
                                    onKeyDown={enterAction}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <h4 className='title-detail'>
                                    Estimativa de economia 
                                </h4>
                            </Col>
                        </Row>
                        <Row className="pb-5">
                            <Col>
                                <table className='table-discounts w-100'>
                                    <tbody>
                                        {/* <tr className='line-details'>
                                            <td className='info'>{doubleToPercent(model.discount)} de desconto*</td>
                                            <td className='value text-end'>{doubleToCurrency(discount)}</td>
                                        </tr> */}
                                        <tr className='line-details'>
                                            <td className='info'>{doubleToPercent(model.cashback)} de dinheiro de volta*</td>
                                            <td className='value text-end'>{doubleToCurrency(cashback)}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr className='line-total'>
                                            <td className='info'>Benefício mensal</td>
                                            <td className='value text-end'>{doubleToCurrency(total)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>

                        <Row className="mt-3 align-items-center">
                            <Col>
                                <a className="text-dark" onClick={handleBack}><u>Voltar</u></a>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button variant="secondary" size="sm" type="button" onClick={handleSubmit}>Próximo</Button>
                            </Col>  
                        </Row>
                    </Form>
                </Col>
                <Col lg={4} className="mb-sm-0 d-flex order-0 order-lg-1 px-0">
                    <CardDiscountComponent discount={discount} cashback={cashback} />
                </Col>
                <Col lg={4} className="d-none d-lg-block text-center order-2">
                    <img src="./assets/img/banners/banner-energia.png" alt="Banner Energia de TODOS" className='img-fluid' />
                </Col>
            </Row>
            <Row className='pt-5'>
                <Col lg={4}>
                    <p className='text-muted'>
                        <i>
                            * O desconto é referente à energia solar injetada no imóvel e sujeita à disponibilidade. Benefício exclusivo para filiados do Cartão de TODOS.  Ao aderir ao Energia de TODOS, garantimos {doubleToPercent(model.cashback)} de desconto na sua conta de energia + 2% de cashback (dinheiro de volta) sobre o valor da conta no app Cartão de TODOS. 
                            <br />
                            ** Qualquer residência na área de concessão da Cemig, com consumo médio a partir de 110 kWh/mês (varia de acordo com o tipo de conexão) e que não tenha nenhum outro subsídio (como tarifa social ou área rural) pode aderir ao Energia de TODOS. 
                            <br />
                            *** O cálculo do desconto é feito com base no padrão comum de energia (monofásico) e pode variar conforme o padrão utilizado na sua região.
                        </i>
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
