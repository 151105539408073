import { useAppDispatch, useAppSelector } from "../../hooks";
import config from '../../../configurations.json';
import { useNavigate } from "react-router-dom";
import { setRedirect } from '../../reducer';
import { RootState } from "../../store";
import { HubspotModel } from "../../models";
import { HubspotService } from "../../services";
import { CalculadoraDynamoModel } from "../../models/calculadora-dynamo";
import { doubleToCurrency, doubleToPercent, getParam, StringToDouble } from "../../utils";
import PartnersList from '../../../partners.json';
import { useState, useEffect } from "react";
import { DropdownItem } from "../../components/dropdown.component";


export const Logic = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [initialized, setInitialized] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [formSended, setFormSended] = useState(false);
    var currentIndex = 0;

    const formClientState = (state: RootState) => state.formClient;
    const formClient = useAppSelector(formClientState);
    const isColaborator = formClient.ssoUserSub ? true : false;

    const configuration = config;

    configuration.health.items = [
        {
            "name": "Clínico Geral",
            "discountFix": StringToDouble(localStorage.getItem('clinico_geral'))
        },
        {
            "name": "Demais Áreas",
            "discountFix": StringToDouble(localStorage.getItem('demais'))
        }
    ];

    const [valorCartao, setValorCartao] = useState(localStorage.getItem("mensalidade_cartao"));

    const getIndex = (value?: number) => {
        if (value && value > 0)
            return currentIndex++;
        else
            return currentIndex;
    }

    const redoCalculations = () => {
        var prevRoute = isColaborator ? "/colaborador" : "/";
        dispatch(setRedirect({ redirect: true }));
        navigate(prevRoute + window.location.search);
    }

    const energyState = (state: RootState) => state.energy;
    const energy = useAppSelector(energyState);
    const farmacyState = (state: RootState) => state.farmacy;
    const farmacy = useAppSelector(farmacyState);
    const gasState = (state: RootState) => state.gas;
    const gas = useAppSelector(gasState);
    const courseState = (state: RootState) => state.course;
    const course = useAppSelector(courseState);
    const healthState = (state: RootState) => state.health;
    const health = useAppSelector(healthState);

    const produtoFarmacia = [
        'Medicamentos de Balcão (Marca)',
        'Medicamentos de Balcão (Genérico)',
        'Artigos e Medicamentos de Prateleira (Marca)',
        'Artigos e Medicamentos de Prateleira (Genérico)',
        'Perfumaria'
    ]
    const frequenciaGas = [
        '1 mês',
        '2 meses',
        '3 meses',
        '4 meses',
        '5 meses',
        '6 meses'
    ]
    const areasCursos = [
        'Cursos Profissionalizantes',
        'Cursos Preparatório'
    ]
    const especialidadeMedica = [
        'Clínico Geral',
        'Demais Áreas'
    ]

    const getMonthlyWhithoutCard = () => {
        return (energy.value ?? 0) + (farmacy.value ?? 0) + (gas.value ?? 0) + (course.value ?? 0) + (health.value ?? 0);
    }

    const getAnualWhithoutCard = () => {
        return getMonthlyWhithoutCard() * 12;
    }

    const getDiscounts = () => {
        return (energy.discount ?? 0) + (farmacy.discount ?? 0) + (gas.discount ?? 0) + (course.discount ?? 0) + (health.discount ?? 0);
    }

    const getCasbacks = () => {
        return (energy.cashback ?? 0) + (farmacy.cashback ?? 0);
    }

    const getMonthlyWithCard = () => {
        let valueEnergy = energy.value - energy.discount - energy.cashback;
        valueEnergy = !isNaN(valueEnergy) ? valueEnergy : 0;

        let valueFarmacy = farmacy.value - farmacy.discount - farmacy.cashback;
        valueFarmacy = !isNaN(valueFarmacy) ? valueFarmacy : 0;

        let valueGas = gas.value - gas.discount;
        valueGas = !isNaN(valueGas) ? valueGas : 0;

        let valueCourse = course.value - course.discount;
        valueCourse = !isNaN(valueCourse) ? valueCourse : 0;

        let valueHealth = health.value - health.discount;
        valueHealth = !isNaN(valueHealth) ? valueHealth : 0;

        return valueEnergy + valueFarmacy + valueGas + valueCourse + valueHealth;
    }

    const getAnualWithCard = () => {
        return Number((getMonthlyWithCard() * 12).toFixed(2));
    }

    const print = () => {
        window.print();
    }

    const beneficiosWhatsApp = () => {
        var text = `
*Calculadora Cartão de TODOS*

Com o Cartão de TODOS, por apenas *R$ ${valorCartao}/mês* você pode *economizar até ${doubleToCurrency(getDiscounts())}* e ainda ganhar *${doubleToCurrency(getCasbacks())} de cashback* todo mês!

Estimativa de gastos *SEM* o Cartão de TODOS 🫢
Gasto mensal: ${doubleToCurrency(getMonthlyWhithoutCard())}
Gasto anual: ${doubleToCurrency(getAnualWhithoutCard())}

Estimativa total de benefícios com o *Cartão de TODOS* 😉
Desconto acumulado: ${doubleToCurrency(getDiscounts())}
Dinheiro de volta acumulado: ${doubleToCurrency(getCasbacks())}
Gasto mensal: ${doubleToCurrency(getMonthlyWithCard())}
Gasto anual: ${doubleToCurrency(getAnualWithCard())}

Seus *Benefícios* com o *Cartão de TODOS*
        `;

        if(energy.value)
            text += `
✅ Estimativa de economia com energia por mês:
${doubleToPercent(configuration.energy.discount ?? 0)} de desconto + ${doubleToPercent(configuration.energy.cashback ?? 0)} de dinheiro de volta
Benefício mensal de ${doubleToCurrency(energy.discount + energy.cashback)}
Benefício anual de  ${doubleToCurrency((energy.discount + energy.cashback)*12)}
            `;

        if(farmacy.value)
            text += `
✅ Estimativa de economia com Farmácia por mês:
${doubleToPercent(configuration.farmacy.items[farmacy.product - 1]?.discount ?? 0)} de desconto + ${doubleToPercent(configuration.farmacy.items[farmacy.product - 1]?.cashback ?? 0)} de dinheiro de volta
Benefício mensal de ${doubleToCurrency(farmacy.discount + farmacy.cashback)}
Benefício anual de  ${doubleToCurrency((farmacy.discount + farmacy.cashback) * 12)}
            `;
    
        if(gas.value)
            text += `
✅ Estimativa de economia com Gás por mês:
${doubleToPercent(configuration.farmacy.items[gas.frequency - 1]?.discount ?? 0)} de desconto
Benefício mensal de ${doubleToCurrency(gas.discount)}
Benefício anual de ${doubleToCurrency(gas.discount * 12)}
            `;

        if(course.value)
            text += `
✅ Estimativa de economia com ${configuration.course.items[course.course - 1]?.name ?? 0}:
${doubleToCurrency(configuration.course.items[course.course - 1]?.discountFix ?? 0)} de desconto
Benefício mensal de ${doubleToCurrency(course.discount)}
Benefício anual de  ${doubleToCurrency((course.discount) * 12)}
            `;

        if(health.value)
            text += `
✅ Estimativa de economia com Consultas médicas:
${doubleToCurrency(configuration.health.items[health.medicalSpecialty - 1]?.discountFix ?? 0)} de desconto
Benefício mensal de ${doubleToCurrency(health.discount)}
Benefício anual de  ${doubleToCurrency((health.discount) * 12)}
            `;

        text += `
🫰Veja também nossos *parceiros* com *cashback*:🤑
        `;

        for (let i = 0; i < PartnersList.length; i++) {
            const category = PartnersList[i];
            for (let x = 0; x < category.partners.length; x++) {
                const partner = category.partners[x];
                text += `
${partner.description.replace('<b>', '*').replace('</b>', '*')}
${partner.readMore && partner.readMore !== '#' ? partner.readMore : ''}
                `;
            }
        }

        text += `
Acesse a calculadora também e veja seus Benefícios:
${window.location.origin}${formClient.idpv ? '?idpv='+formClient.idpv : ""}
        `;

        return text;
    };

    const beneficiosEmail = () => {
        var text = `
Calculadora Cartão de TODOS

Com o Cartão de TODOS, por apenas R$ ${valorCartao}/mês você pode economizar até ${doubleToCurrency(getDiscounts())} e ainda ganhar ${doubleToCurrency(getCasbacks())} de cashback todo mês!

Estimativa de gastos SEM o Cartão de TODOS 😢
Gasto mensal: ${doubleToCurrency(getMonthlyWhithoutCard())}
Gasto anual: ${doubleToCurrency(getAnualWhithoutCard())}

Estimativa total de benefícios com o Cartão de TODOS 😉
Desconto acumulado: ${doubleToCurrency(getDiscounts())}
Dinheiro de volta acumulado: ${doubleToCurrency(getCasbacks())}
Gasto mensal: ${doubleToCurrency(getMonthlyWithCard())}
Gasto anual: ${doubleToCurrency(getAnualWithCard())}

Acesse a calculadora também e veja seus Benefícios:
${window.location.origin}${formClient.idpv ? '?idpv='+formClient.idpv : ""}
        `;


        return text;
    };

    const sendWhatsApp = () => {
        var text = beneficiosWhatsApp();
        var link = `https://api.whatsapp.com/send/?phone=55${formClient.phone}&text=${encodeURIComponent(text)}&type=phone_number&app_absent=0`;
        window.open(link);
    }

    const sendEmail = () => {
        var text = beneficiosEmail();
        var link = `mailto:${formClient.email}?subject=Calculadora de Benefícios Cartão de TODOS&body=${encodeURIComponent(text)}`;
        window.open(link)
    }

    async function sendForm(formRetencao: boolean = false) {
        
        var form = {
            hbs: {
                email: formClient.email,
                valor_mensal_com_energia: energy.value?.toString(),
                tipo_de_produto_farmacia_mensal: produtoFarmacia[(farmacy.product - 1)],
                valor_mensal_com_farmacia: farmacy.value?.toString(),
                frequencia_da_compra_de_gas: gas.frequency,
                valor_do_gas: gas.value?.toString(),
                field_of_study: areasCursos[(course.course - 1)],
                valor_mensal_com_estudo: course.value?.toString(),
                especialidade_mensal_medico: especialidadeMedica[(health.medicalSpecialty - 1)],
                valor_mensal_com_medico: health.value?.toString(),
                gasto_mensal_total_sem_desconto: getMonthlyWhithoutCard()?.toString(),
                economia_mensal_total_estimada: getDiscounts()?.toString(),
            } as HubspotModel,

            dynamo: {
                email: formClient.email,
                cpf: formClient.cpf ? formClient.cpf : 'nao_obrigatorio',
                nome: formClient.name,
                telefone: formClient.phone,
                cep: formClient.cep,
                estado: formClient.uf,
                bairro: formClient.bairro,
                cidade: formClient.cidade,

                opt_in_email: formClient.termsEmail,
                opt_in_whatsapp: formClient.termsWhatsapp,
                opt_in_sms: formClient.termsSMS,
                opt_in_termos: formClient.terms,

                //Área do colaborador
                franquia: formClient.franchise && formClient.registration ? formClient.franchise : 'nao_obrigatorio',
                franquia_proxima: formClient.nearestFranchise ? formClient.nearestFranchise : 'nao_obrigatorio',
                regional: formClient.regional ? formClient.regional : 'nao_obrigatorio',
                id_matricula: formClient.registration ? formClient.registration : 'nao_obrigatorio',
                atendente: formClient.attendantName ? formClient.attendantName : 'nao_obrigatorio',
                cliente_foi_retido: dropdownClienteRetidoValue > 0 ? configuration.total.retidos[dropdownClienteRetidoValue - 1].value : 'nao_obrigatorio',
                motivo: dropdownMotivoValue > 0 ? configuration.total.motivos[dropdownMotivoValue - 1].name : 'nao_obrigatorio',
    
                //Energia de todos
                vlr_mensal_energia: energy.value,
                perc_desc_energia: Number((configuration.energy.discount * 100 ?? 0).toFixed(2)),
                vlr_desc_energia: energy.discount,
                perc_cash_energia: Number((configuration.energy.cashback * 100 ?? 0).toFixed(2)),
                vlr_cash_energia: energy.cashback,
                total_benef_energia: Number((energy.discount ?? 0 + energy.cashback ?? 0).toFixed(2)),
    
                //Farmacia
                tipo_medicamento: produtoFarmacia[(farmacy.product - 1)],
                vlr_mensal_farmacia: farmacy.value,
                perc_desc_farmacia: Number((configuration.farmacy.items[farmacy.product - 1]?.discount ?? 0 * 100).toFixed(2)),
                vlr_desc_farmacia: farmacy.discount,
                perc_cash_farmacia: Number((configuration.farmacy.items[farmacy.product - 1]?.cashback ?? 0 * 100).toFixed(2)),
                vlr_cash_farmacia: farmacy.cashback,
                total_benef_farmacia: Number((farmacy.discount ?? 0 + farmacy.cashback ?? 0).toFixed(2)),
    
                //Gas
                frequencia_gas: frequenciaGas[(gas.frequency - 1)],
                vlr_mensal_gas: gas.value,
                perc_desc_gas: Number((configuration.gas.items[gas.frequency - 1]?.discount ?? 0 * 100).toFixed(2)),
                vlr_desc_gas: gas.discount,
                total_benef_gas: gas.discount,
    
                //Cursos Refuturiza
                course_area: areasCursos[(course.course - 1)],
                vlr_mensal_course: course.value,
                vlr_desc_course: Number((configuration.course.items[course.course - 1]?.discountFix ?? 0).toFixed(2)),
                total_benef_course: course.discount,
    
                //AmorSaúde
                especialidade_health: especialidadeMedica[(health.medicalSpecialty - 1)],
                vlr_mensal_health: health.value,
                vlr_desc_health: Number((configuration.health.items[health.medicalSpecialty - 1]?.discountFix ?? 0).toFixed(2)),
                total_benef_health: health.discount,
    
                // totais
                vlr_total_cashback: Number(getCasbacks().toFixed(2)),
                vlr_total_desconto: Number(getDiscounts().toFixed(2)),
                vlr_benef_total_mensal: Number((getCasbacks() + getDiscounts()).toFixed(2)),
                vlr_gasto_mensal: Number(getMonthlyWithCard().toFixed(2)),
                vlr_gasto_anual: Number(getAnualWithCard().toFixed(2)),

                //Dados do Colaborador
                nomeColaborador: formClient.ssoUserName ? formClient.ssoUserName : 'nao_obrigatorio',
                emailColaborador: formClient.ssoUserEmail ? formClient.ssoUserEmail : 'nao_obrigatorio',
                idColaborador: formClient.ssoUserSub ? formClient.ssoUserSub : 'nao_obrigatorio',

            } as CalculadoraDynamoModel
        };
        if(formRetencao) {
            if(dropdownMotivoValue && dropdownClienteRetidoValue) {
                await HubspotService().sendLead(form);
                setFormSended(true);
                setTimeout(() => {
                    setFormSended(false);
                }, 5000);
            }
        }
        else {
            await HubspotService().sendLead(form);
        }
    }

    const [dropdownClienteRetidoValue, setDropdownClienteRetidoValue] = useState(0) || undefined;
    const [showDropdownRequiredRetido, setShowDropdownRequiredRetido] = useState(false);
    const clienteRetido = configuration.total.retidos.map(x => new DropdownItem(configuration.total.retidos.indexOf(x) + 1, x.name));
    const setDropDownClienteRetido = (value: number) => {
        setDropdownClienteRetidoValue(val => val = value);
        setShowDropdownRequiredRetido(dropdownClienteRetidoValue ? false:true);
    }

    const [dropdownMotivoValue, setDropdownMotivoValue] = useState(0) || undefined;
    const [showDropdownRequiredMotivo, setShowDropdownRequiredMotivo] = useState(false);
    const motivos = configuration.total.motivos.map(x => new DropdownItem(configuration.total.motivos.indexOf(x) + 1, x.name));
    const setDropDownMotivo = (value: number) => {
        setDropdownMotivoValue(val => val = value);
        setShowDropdownRequiredMotivo(dropdownMotivoValue ? false:true);   
    }

    
    const verifyForm = () => {
        if(dropdownMotivoValue && dropdownClienteRetidoValue) {
            setFormValid(true);
            setShowDropdownRequiredRetido(false);
            setShowDropdownRequiredMotivo(false);
            sendForm(true);
        }
        else {
            setFormValid(false);
            setShowDropdownRequiredRetido(true);
            setShowDropdownRequiredMotivo(true);
        }
    }

    const filledValues = (): boolean => {
        return energy.value || farmacy.value || gas.value || course.value || health.value ? true : false;
    }

    const verify = () => {
        setFormValid(dropdownClienteRetidoValue && dropdownMotivoValue ? true : false);
    }
    
    if (!initialized) {
        setInitialized(value => value = true);
        sendForm();
    }

    return { 
        redoCalculations, formClient, energy, farmacy, gas, course, health, configuration, 
        getIndex, getMonthlyWhithoutCard, getAnualWhithoutCard, getDiscounts, getCasbacks, getMonthlyWithCard, getAnualWithCard, print, sendWhatsApp, 
        sendEmail, isColaborator, dropdownMotivoValue, setDropdownMotivoValue, motivos, setDropDownMotivo ,
        showDropdownRequiredMotivo, setShowDropdownRequiredMotivo,
        dropdownClienteRetidoValue, setDropdownClienteRetidoValue, clienteRetido, setDropDownClienteRetido,
        showDropdownRequiredRetido, setShowDropdownRequiredRetido,
        verifyForm, formValid, verify, formSended, setFormSended, filledValues,
        valorCartao
    };
}
