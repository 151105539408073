import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CourseStateModel {
    page: string;
    course: number;
    value: number;
    discount: number;
}

export const IndexReducer = () => {
    const setContextRule = (model: CourseStateModel, payload: PayloadAction<CourseStateModel>) => model = payload.payload;

    const indexSlice = createSlice({
        name: 'course',
        initialState: {} as CourseStateModel,
        reducers: {
            setContextCourse: (model: CourseStateModel, payload: PayloadAction<CourseStateModel>) => setContextRule(model, payload),
        }
    });

    return { indexSlice };
}

export const { indexSlice } = IndexReducer();
export const { setContextCourse } = indexSlice.actions;

export default indexSlice.reducer;