import { doubleToPercent, doubleToCurrency } from "../utils";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as HelpIcon } from "../assets/img/icons/ic-help.svg";

export interface ModelObject {
    page: string;
    value: number;
    discount?: number;
    cashback?: number;
}

const StrippedCardComponent = (props: {index: number, page: string, logo: string, height: number, description: string, readMore: string, model: ModelObject, discount?: number, discountFix?: number, cashback?: number, text?: string }) => {
    const { index, page, logo, height, description, readMore, model, discount, discountFix, cashback, text } = props;
     
    return (
        model.value && model.value > 0 ? 
        <Row className={(index % 2 === 0 ? "bg-yellow print-spacer" : "bg-light print-spacer")}>
            <Col sm={6} className="p-3 p-lg-5 border-end print-space-reset">
                <Row className='mb-3 print-space-reset'>
                    <Col className="col-auto">
                        <img src={logo} alt={page} height={height} />
                    </Col>
                </Row>
                <Row className='mb-2 mb-lg-5 page-title print-space-reset'>
                    <Col className="col-auto">
                        <h4 dangerouslySetInnerHTML={{ __html: description }}></h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-auto">
                        {readMore !== '#' && readMore ? 
                            <span className="d-inline-block">
                                <a href={readMore} target="_blank" className="underline text-link no-print">Saiba mais</a>
                            </span>
                            :
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Já solicitamos o link, em breve ele chega 👍</Tooltip>}>
                                <span className="d-inline-block">
                                    <a href={readMore} className="underline text-link no-print">Saiba mais</a>
                                </span>
                            </OverlayTrigger>
                        }
                    </Col>
                </Row>
            </Col>
            
            <Col sm={6} className="p-3 pb-5 p-lg-5 print-space-reset">
                <Row className="pb-1 align-items-baseline print-space-reset">
                    <Col className="col-auto">
                        <h4 className='title-detail'>
                            Estimativa de economia 
                        </h4>
                    </Col>
                    <Col className="col-auto no-print">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
                            Os valores abaixo são uma 
                            <br />
                            estimativa de economia com 
                            <br />
                            base nos dados inseridos na 
                            <br />
                            calculadora e podem sofrer 
                            <br />
                            variações.
                        </Tooltip>}>
                            <HelpIcon></HelpIcon>
                        </OverlayTrigger>
                    </Col>
                </Row>

                <Row className="mt-3 print-space-reset">
                    <Col>
                        <table className='table-resume w-100'>
                            <tbody>
                                {discount ?
                                    <tr className='line-details'>
                                        <td className='info'>{doubleToPercent(discount ?? 0)} de desconto</td>
                                        <td className='value text-end'>{doubleToCurrency(model.discount ?? 0)}</td>
                                    </tr>
                                :
                                    <tr className='line-details'>
                                        <td className='info'>{text}</td>
                                        <td className='value text-end'>{doubleToCurrency(discountFix ?? 0)}</td>
                                    </tr>
                                }
                                {cashback ?
                                    <tr className='line-details'>
                                        <td className='info'>{doubleToPercent(cashback ?? 0)} de dinheiro de volta</td>
                                        <td className='value text-end'>{doubleToCurrency(model.cashback ?? 0)}</td>
                                    </tr>
                                :
                                    <></>
                                }
                            </tbody>
                            <tfoot>
                                <tr className='line-total'>
                                    <td className='info text-primary'>Benefício mensal</td>
                                    <td className='value text-end'>{doubleToCurrency((model.discount ?? 0) + (model.cashback ?? 0))}</td>
                                </tr>
                                <tr className='line-total'>
                                    <td className='info text-primary'>Benefício anual</td>
                                    <td className='value text-end'>{doubleToCurrency(((model.discount ?? 0) + (model.cashback ?? 0)) * 12)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </Col>
                </Row>
            </Col >
        </Row> 
        : 
        <></>
    );
}

export default StrippedCardComponent;