import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FarmacyStateModel {
    page: string;
    product: number;
    value: number;
    discount: number;
    cashback: number;
}

export const IndexReducer = () => {
    const setContextRule = (model: FarmacyStateModel, payload: PayloadAction<FarmacyStateModel>) => model = payload.payload;

    const indexSlice = createSlice({
        name: 'farmacy',
        initialState: {} as FarmacyStateModel,
        reducers: {
            setContextFarmacy: (model: FarmacyStateModel, payload: PayloadAction<FarmacyStateModel>) => setContextRule(model, payload),
        }
    });

    return { indexSlice };
}

export const { indexSlice } = IndexReducer();
export const { setContextFarmacy } = indexSlice.actions;

export default indexSlice.reducer;