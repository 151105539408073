import { DropdownItem } from "../../components/dropdown.component";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { clearCurrency, doubleToString, GetAbrangencia, maskVal } from "../../utils";
import configuration from '../../../configurations.json';
import { GasStateModel, setContextGas } from "./reducer";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { setRedirect } from "../../reducer";

export const Logic = () => {
    const [initialized, setInitialized] = useState(false);
    const currentState = (state: RootState) => state.gas;
    const state = useAppSelector(currentState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const abrangencia = GetAbrangencia();

    const formClientState = (state: RootState) => state.formClient;
    const formClient = useAppSelector(formClientState);
    const isColaborator = formClient.ssoUserSub ? true : false;

    const [value, setValue] = useState(doubleToString(state.value) || '');
    const [discount, setDiscount] = useState(state.discount || 0);
    const [mounthDiscount, setmounthDiscount] = useState(state.mounthDiscount || 0);
    const [total, setTotal] = useState(0);
    function verifyDiscount(index?: number) {
        index = (index! ?? dropdownValue!) - 1;
        if(index >= 0) {

            setDiscount(val => val = Number(((clearCurrency(value) / (index! + 1)) * configuration.gas.items[index!].discount).toFixed(2)));
    
            let desc = Number(((clearCurrency(value)) * configuration.gas.items[index!].discount).toFixed(2));
            setmounthDiscount(val => val = desc);
            setTotal(desc);
        }
        else {
            setDiscount(0);
            setmounthDiscount(0);
            setTotal(0);
        }
    }

    const [dropdownValue, setDropdownValue] = useState(state.frequency || 0);

    const items = configuration.gas.items.map(x => new DropdownItem(configuration.gas.items.indexOf(x) + 1, x.name));

    const handleBack = () => {
        var prevRoute = isColaborator ? "/colaborador" : "/";
        if (abrangencia.atendeFarmacia)
            prevRoute = "/farmacy";
        else if (abrangencia.atendeEnergiaTodos)
            prevRoute = "/energy";
        dispatch(setRedirect({ redirect: true }));
        navigate(prevRoute + window.location.search);
    }

    const handleSubmit = () => {
        dispatch(setContextGas({ page: 'gas', value: clearCurrency(value), frequency: dropdownValue, discount, mounthDiscount, cashback: 0 } as GasStateModel));
        dispatch(setRedirect({ redirect: true }));
        navigate("/course" + window.location.search);
    }

    if (!initialized && state.value) {
        setInitialized(value => value = true);
        verifyDiscount();
        setTotal(discount);
    }

    const energyState = (state: RootState) => state.energy;
    const energy = useAppSelector(energyState);
    const farmacyState = (state: RootState) => state.farmacy;
    const farmacy = useAppSelector(farmacyState);

    const getDiscountCard = () => {
        let value = (energy.discount ?? 0) + (farmacy.discount ?? 0) + discount;
        return !isNaN(value) ? value : 0;
    }

    const getCashbackCard = () => {
        let value = (energy.cashback ?? 0) + (farmacy.cashback ?? 0);
        return !isNaN(value) ? value : 0;
    }


    const enterAction = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const setDropDown = (value: number) => {
        setDropdownValue(val => val = value);
        verifyDiscount(value);
    }

    useEffect(() => {
        if(value) {
            setValue(maskVal(value));
            verifyDiscount();
        }
    }, [value, setValue]);

    return { items, dropdownValue, value, setValue, verifyDiscount, handleBack, handleSubmit, discount, mounthDiscount, getDiscountCard, getCashbackCard, enterAction, setDropDown, total, model: configuration.gas };
}