import { DropdownItem } from "../../components/dropdown.component";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { clearCurrency, doubleToCurrency, doubleToString, GetAbrangencia, maskVal } from "../../utils";
import { CourseStateModel, setContextCourse } from "./reducer";
import configuration from '../../../configurations.json';
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { setRedirect } from "../../reducer";

export const Logic = () => {
    const [initialized, setInitialized] = useState(false);
    const currentState = (state: RootState) => state.course;
    const state = useAppSelector(currentState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const abrangencia = GetAbrangencia();


    const formClientState = (state: RootState) => state.formClient;
    const formClient = useAppSelector(formClientState);
    const isColaborator = formClient.ssoUserSub ? true : false;

    const [value, setValue] = useState(doubleToString(state.value) || '');
    const [monthly, setMonthly] = useState(doubleToCurrency(configuration.course.items[0].discountFix));
    const [discount, setDiscount] = useState(state.discount || 0);
    const [total, setTotal] = useState(0);
    
    const [dropdownValue, setDropdownValue] = useState(state.course || 0);
    const items = configuration.course.items.map(x => new DropdownItem(configuration.course.items.indexOf(x) + 1, x.name));

    function verifyDiscount(index?: number) {
        index = (index! ?? dropdownValue!) - 1;
        let val = clearCurrency(value);
        let desc = index >= 0 ? configuration.course.items[index!].discountFix : 0;
        let total = val >= desc ? val - desc : 0;

        setMonthly(doubleToCurrency(configuration.course.items[index!].discountFix));
        setDiscount(val => val = total);

        setTotal(total);
    }
    
    const handleBack = () => {
        var prevRoute = isColaborator ? "/colaborador" : "/";
        if (abrangencia.atendeGas)
            prevRoute = "/gas";
        else if (abrangencia.atendeFarmacia)
            prevRoute = "/farmacy";
        else if (abrangencia.atendeEnergiaTodos)
            prevRoute = "/energy";
        
        dispatch(setRedirect({ redirect: true }));
        navigate(prevRoute + window.location.search);
    }

    const handleSubmit = () => {
        dispatch(setContextCourse({ page: 'course', value: clearCurrency(value), course: dropdownValue, discount, cashback: 0 } as CourseStateModel));
        dispatch(setRedirect({ redirect: true }));
        navigate("/health" + window.location.search)
    }

    if (!initialized && state.value) {
        setInitialized(value => value = true);
        verifyDiscount();
    }

    const energyState = (state: RootState) => state.energy;
    const energy = useAppSelector(energyState);
    const farmacyState = (state: RootState) => state.farmacy;
    const farmacy = useAppSelector(farmacyState);
    const gasState = (state: RootState) => state.gas;
    const gas = useAppSelector(gasState);

    const getDiscountCard = () => {
        let value = (energy.discount ?? 0) + (farmacy.discount ?? 0) + (gas.discount ?? 0) + discount;
        return !isNaN(value) ? value : 0;
    }

    const getCashbackCard = () => {
        let value = (energy.cashback ?? 0) + (farmacy.cashback ?? 0);
        return !isNaN(value) ? value : 0;
    }

    const enterAction = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const setDropDown = (value: number) => {
        setDropdownValue(val => val = value);
        verifyDiscount(value);
    }

    useEffect(() => {
        if(value) {
            setValue(maskVal(value));
            verifyDiscount();
        }
    }, [value, setValue]);

    return { items, dropdownValue, value, setValue, monthly, setMonthly, verifyDiscount, handleBack, handleSubmit, discount, getDiscountCard, getCashbackCard, enterAction, total, setDropDown, model: configuration.course };
}