export class CepModel {
    uf?: string;
    logradouro?: string;
    bairro?: string;
    cidade?: string;
    gateway?: string;
    ibge?: string;
    franquiactn?: string;
    franquiaNome?: string;
    regionalId?: number;
    regionalNome?: string;
}