import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import formClientReducer from './pages/form-client/reducer';
import receiptReducer from './pages/receipt/reducer';
import farmacyReducer from './pages/farmacy/reducer';
import energyReducer from './pages/energy/reducer';
import courseReducer from './pages/course/reducer';
import healthReducer from './pages/health/reducer';
import totalReducer from './pages/total/reducer';
import gasReducer from './pages/gas/reducer';
import appReducer from './reducer';

export const store = configureStore({
  reducer: {
    app: appReducer,
    formClient: formClientReducer,
    energy: energyReducer,
    farmacy: farmacyReducer,
    gas: gasReducer,
    course: courseReducer,
    health: healthReducer,
    total: totalReducer,
    receipt: receiptReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
