import axios from "axios";

export const EmailService = () => {
    const validarEmail = async (email: string) => {
        try {
            var response = await axios.get(`${process.env.REACT_APP_API}/validate/email/${email}`);
            return response.data;
        } catch (error) {
            return undefined;
        }
    };

    return { validarEmail };
}